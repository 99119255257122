import { Component, OnInit } from '@angular/core';

import { JobService } from '../../shared/job.service';

import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-management',
  templateUrl: './service-management.component.html',
  styleUrls: ['./service-management.component.css']
})
export class ServiceManagementComponent implements OnInit {
	service:any;
	baseurl:any;
  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }

  ngOnInit() {

		var role =  localStorage.getItem('role');
		if(role =="jobSeekar")
		{
		this.router.navigate(['/findwork']);
		} 
	  if(role == "jobProvider")
		{
		  this.router.navigate(['/jobpost']);
		}
		this.baseurl = environment.imgBaseUrl+"/services";
    this.adminservice.getServices().subscribe(
      (res: any) => {
        this.service = res.data;
      }
    );
  }

  deleteservice(serviceid)
	{
		if(confirm("Are you sure to delete")) {


		this.adminservice.deleteService(serviceid).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Delete Successfullly');
				
				this.router.navigate(['/admin/service-management']);
					 
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

		this.router.navigate(['/admin/service-management']);
	}


else

{
	return false;
}

}
  
 

}
