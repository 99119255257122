import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
@Component({
	selector: 'app-admin-dashboard',
	templateUrl: './admin-dashboard.component.html',
	styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
	constructor(private router: Router) { }
	ngOnInit() {
	
		if (localStorage.getItem("token") == null) {
			this.router.navigate(['/login']);
		}
	}
}
