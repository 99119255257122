

import { Component, OnInit } from '@angular/core';
import { AdminService } from './../shared/admin.service';
import { UserService } from './../shared/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private domSanitizer: DomSanitizer,private toastr:ToastrManager,private adminservice:AdminService,private Userservice:UserService) { }
  pagedata:any;
  public imagesUrl;
  joinupperfooterarea: any;
  mainfooter:any;
  baseurl:any;


  toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
}
  ngOnInit() {

    this.baseurl ="http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/uploads";
      
        this.adminservice.pagedetail('5cade28d30ba7e4608850880').subscribe(
          (res: any) => {
            if (res.data) {
             this.joinupperfooterarea = res.data[0].pageContent;
                      }
          }, err => {
            alert("error" + err);
          }
        );

        this.adminservice.pagedetail('5cade4aca4bcf946e97d8ecb').subscribe(
          (res: any) => {
            if (res.data) {
             this.mainfooter = res.data[0].pageContent;
            }
          }, err => {
            alert("error" + err);
          }
        );

        
  }

  
}
