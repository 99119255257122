import { Component, OnInit } from '@angular/core';
import { UserService  } from '../../shared/user.service';

import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {

  constructor(private userService:UserService,private route: ActivatedRoute, private router: Router) { }
verified :boolean;
  ngOnInit() {

    var  userid = this.route.snapshot.paramMap.get('id');

    this.userService.verifyUser(userid).subscribe(
			(res: any) => {

       
				if (res.docs == null) {
					this.verified = false;
				} else {
					this.verified = true;
				}
			},
			err => {

				alert("error" + err);
			}
		);
  }

}
