import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { UserService } from '../../shared/user.service';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.css'],
	providers: [UserService]
})
export class SignUpComponent implements OnInit {

	serverErrorMessages: boolean;
	roleType: string;
	step1: string;

	phonecheckmessage:boolean;

	constructor(private spinnerService: Ng4LoadingSpinnerService,private userService: UserService, private http: HttpClient, private location: Location, private route: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		this.spinnerService.hide();
		let userrole = this.route.snapshot.paramMap.get('id');
		localStorage.setItem("role", userrole);
		this.roleType = userrole;
	}

	phonenumbr(event)
	{
		var phonevalue= event.target.value;
		this.userService.checkphone({phone:phonevalue}).subscribe(
			(res: any) => {
				if (res.data == "") {
				this.phonecheckmessage = false;
				}
				else
				{
					this.phonecheckmessage = true;
				}

			});
	}
	emailcheck(event)
	{
		var emailvalue= event.target.value;
		this.userService.checkemail({email:emailvalue}).subscribe(
			(res: any) => {
				if (res.docs == null) {
					this.serverErrorMessages = false;
				}
				else
				{
					this.serverErrorMessages = true;
				}

			});
	}
	onSubmit(form: NgForm) {
		this.spinnerService.show();
		this.userService.checkemail(form.value).subscribe(
			(res: any) => {
				if (res.docs == null) {
				this.userService.checkphone(form.value).subscribe(
					(res: any) => {
						if (res.data == "") {
					this.phonecheckmessage = false;
					this.spinnerService.hide();
					localStorage.setItem("firstName", form.value.firstName);
					localStorage.setItem("lastName", form.value.lastName);
					localStorage.setItem("email", form.value.email);
					localStorage.setItem("password", form.value.password);
					localStorage.setItem("password", form.value.cnfPassword);
					localStorage.setItem("phone", form.value.phone);
					localStorage.setItem("role", form.value.role);
					this.step1 = "success";
					this.router.navigate(['/signup-step2']);
						}
						else
						{

							this.phonecheckmessage = true;
							this.serverErrorMessages = false;
						}
					}
				);
				}
				else {
					this.serverErrorMessages = true;
				}
			});


	}

	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		  return false;
		}
		return true;
	
	  }
}