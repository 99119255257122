import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { UserService } from '../../../shared/user.service';
import { environment } from '../../../../environments/environment';
import { JobService } from '../../../shared/job.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {ImageCropperComponent, CropperSettings} from 'ng2-img-cropper';
import { CropperOption } from 'ngx-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';


@Component({
	selector: 'app-profile-setting',
	templateUrl: './profile-setting.component.html',
	styleUrls: ['./profile-setting.component.css']
})
export class ProfileSettingComponent implements OnInit {

	public cropperConfig: CropperOption;
	data: any;
	cropperSettings: CropperSettings;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	userprofile:any;
	banks:any;
	// serverErrorMessages: string;
	showSucessMessage: boolean;
	countries: string;
	service: string;
	baseurl: string;
	imageSrc: any;
	imagename: string;
	docfiles: any;
	
	public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl+'/uploaddociid', itemAlias: 'uploadId'
	});
	role: string;

	constructor(private spinnerService: Ng4LoadingSpinnerService,private userService: UserService, private router: Router, private JobService: JobService, public toastr: ToastrManager) {
		this.cropperSettings = new CropperSettings();
		this.cropperSettings.width = 70;
		this.cropperSettings.height = 70;
		this.cropperSettings.croppedWidth = 70;
		this.cropperSettings.croppedHeight = 70;
		this.cropperSettings.canvasWidth = 200;
		this.cropperSettings.canvasHeight = 200;
		this.cropperSettings.rounded=true;
		this.cropperSettings.touchRadius=37;
		this.cropperSettings.touchRadius=35;
		this.cropperSettings.dynamicSizing=true;
		this.cropperSettings.noFileInput=true;
        this.cropperSettings.cropperClass="croppimag";
		this.data = {};

	 }

	ngOnInit() {
	
		this.spinnerService.hide();
		console.log(localStorage.getItem('role'));
		this.role = localStorage.getItem('role');
		this.baseurl = environment.imgBaseUrl;
		this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			var filname = JSON.parse(response);
			this.docfiles = filname.filename;

			localStorage.setItem("docfile", this.docfiles);
		}

		this.baseurl = environment.imgBaseUrl;

		if (localStorage.getItem("token") == null) {
			this.router.navigate(['/login']);
		}

		var useremail = localStorage.getItem("id");

		this.userService.gettUser(useremail).subscribe(
			(res: any) => {
				// console.log(res.docs);
				this.userprofile = res.data;
				this.userprofile.accountNo = res.data.accountNo;
				localStorage.setItem("image", res.data.image);
				localStorage.setItem("docfiles", res.data.docfiles);
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				//alert("erro");
			}
		);
		this.JobService.getCountries().subscribe(
			(res:any) => {
				this.countries = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				// if (err.status == 422) {
				// 	this.serverErrorMessages = err.error.join('<br/ >');
				// } else
				// 	this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);

		this.JobService.getServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

		this.userService.getBanks().subscribe(
			(res: any) => {
				this.banks = res.data;
			}
		);
	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	 imageCropped(event: any) {
	
		this.data.image = event.base64;
	}
	imageLoaded() {
		// show cropper
	}
	loadImageFailed() {
		// show message
	}

	onSubmit(form: NgForm) {
		this.spinnerService.show();
		if(this.data.image)
		{
		localStorage.setItem("image",this.data.image);
		}
			// console.log(form.value);
		setTimeout(() => {
		this.userService.updateUser(form.value).subscribe(
			(res:any) => {
				this.toastr.successToastr('Success!');
				// this.showSucessMessage = true;
				console.log("res",res.data)
				localStorage.setItem("street",res.data.street)
				localStorage.setItem("houseNo",res.data.houseNo)
				localStorage.setItem("area",res.data.area)

				setTimeout(() => {
				//	this.router.navigate(['/dashboard']);
				//	this.spinnerService.hide();
				}, 3000);
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				// if (err.status == 422) {
				// 	this.serverErrorMessages = err.error.join('<br/ >');
				// }
				// else
				// 	this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);
		},5000);
	}

	readURL(event): void {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;
			reader.readAsDataURL(file);
		}
	}



	mychange(val) {
		
		const self = this;
		let chIbn = val.toString().split('-').join('');
		if (chIbn.length > 0) {
		  chIbn = chIbn.match(new RegExp('.{1,4}', 'g')).join('-');
		}
		this.userprofile.accountNo = chIbn;
	  }
}