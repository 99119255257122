import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { UserService } from '../../shared/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {DomSanitizer} from '@angular/platform-browser';
import { JobService } from '../../shared/job.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.css']
})
export class LoginFooterComponent implements OnInit {

  constructor(private JobService:JobService,private domSanitizer: DomSanitizer,private toastr:ToastrManager,private adminservice:AdminService,private Userservice:UserService) { }
  mainfooter:any;
  ngOnInit() {

    this.adminservice.pagedetail('5cc15f2d786b2a79cc864b80').subscribe(
      (res: any) => {
        if (res.data) {
         this.mainfooter = res.data[0].pageContent;
        }
      }, err => {
        alert("error" + err);
      }
    );
  }


 
}
