import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AdminService } from '../../shared/admin.service';

@Component({
  selector: 'app-edit-country',
  templateUrl: './edit-country.component.html',
  styleUrls: ['./edit-country.component.css']
})
export class EditCountryComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,private route: ActivatedRoute, private router: Router, private adminService: AdminService, public toastr: ToastrManager) { }
  countrydata:string;
  ngOnInit() {
		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

    let countryid = this.route.snapshot.paramMap.get('id');
    this.adminService.countrydetail(countryid).subscribe(
			(res: any) => {
				if (res.data) {
					this.countrydata = res.data[0];
			
				}
			}, err => {
				alert("error" + err);
			}
		);
  }
  onSubmit(form: NgForm) {
    this.spinnerService.show();
    let countryid = this.route.snapshot.paramMap.get('id');
    form.value.countryid = countryid;
    this.adminService.updatecountry(form.value).subscribe(
    (res:any) => {
      this.spinnerService.hide();
      this.router.navigate(['/admin/country-management']);
  
          this.toastr.successToastr('Country update  Successfullly');
                }
    ,
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}

}
