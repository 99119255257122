import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { createNgModuleFactory } from '@angular/core/src/view';
import { analyzeAndValidateNgModules } from '@angular/compiler';


@Injectable({
	providedIn: 'root'
})

export class JobService {
	selectedjob = {
		jobtitle: '',
		category: '',
		expirejob: '',
		location: '',
		jobdescription: '',
		additionalinfo: '',
		feature: ''
	}

	imageChangedEvent: any = '';
	croppedImage: any = '';
	dataimage: any;
	constructor(private http: HttpClient) {
		if (localStorage.getItem("token") != null) {
			var session = localStorage.getItem("token");
		}
	}

	// post jobs
	jobPost(Job: any) {
		console.log("Job",Job)
		var userid = localStorage.getItem('id');
		Job.userid = localStorage.getItem('id');
		return this.http.post(environment.apiBaseUrl + '/jobpost', Job);
	}
	//get rating
	startRating() {
		var id = localStorage.getItem('jobproviderid');
		let userdata = { "usreid": id };
		return this.http.post(environment.apiBaseUrl + '/getrateReview', userdata);
	}
	// get user job
	getjobs(data) {
		return this.http.post(environment.apiBaseUrl + '/getjobs', data);
	}

	// Get jobs apply
	getServiceProviderJobs(data) {
		return this.http.post(environment.apiBaseUrl + '/getjobapplied', data);
	}

	//view job
	viewjob(jobid) {
		var data = { jobid: jobid };
		return this.http.post(environment.apiBaseUrl + '/viewjob', data);
	}

	// Get all jobs
	getAlljobs(data) {
		return this.http.post(environment.apiBaseUrl + '/getAlljobs', data);
	}

	// getJobByCategory
	getJobByCategory(category) {
		var searchtext = localStorage.getItem("searchtext");
		var sortorder = localStorage.getItem("sortorder");
		var categoryData = { category: JSON.parse(category.category), "pageSize": category.pageSize, "pageNo": category.pageNo, "searchtext": searchtext, "sortorder": sortorder };
		return this.http.post(environment.apiBaseUrl + '/jobsearch', categoryData);
	}
	// jobsearch by type word
	jobSearchByWord(typeword) {

		if (localStorage.getItem("category") == "") {
			var category = "[]";
		}
		else {
			var category = localStorage.getItem("category");
		}

		var sortorder = localStorage.getItem("sortorder");
		var jobsearchdata = { "category": JSON.parse(category), "searchtext": typeword.searchtext, "pageSize": typeword.pageSize, "pageNo": typeword.pageNo, "sortorder": sortorder };
		return this.http.post(environment.apiBaseUrl + '/jobsearch', jobsearchdata);
	}

	//Job sorting
	jobSorting(sortorder) {
		if (localStorage.getItem("category") == "") {
			var category = "[]";
		}
		else {
			var category = localStorage.getItem("category");
		}
		var searchtext = localStorage.getItem("searchtext");
		var jobsortdata = { "sortorder": sortorder.sortorder, "category": JSON.parse(category), "searchtext": searchtext, "pageNo": sortorder.pageNo, "pageSize": sortorder.pageSize };
		return this.http.post(environment.apiBaseUrl + '/jobsearch', jobsortdata);
	}

	//jobsearch pagination
	jobsearchpagination(searechdata) {
		return this.http.post(environment.apiBaseUrl + '/jobsearch', searechdata);
	}

	// Job Apply
	jobApply(jobApplyDetails) {
		var userid = localStorage.getItem('id');
		var jobapllydata = { "userid": userid, "jobApplyDetails": jobApplyDetails };
		return this.http.post(environment.apiBaseUrl + '/jobapply', jobapllydata);
	}

	// check Job Apply
	checkJobApply(jobid) {
		var userid = localStorage.getItem('id');
		var checkjobdata = { "userid": userid, "jobid": jobid };
		return this.http.post(environment.apiBaseUrl + '/checkjobAplly', checkjobdata);
	}

	//Getcategory
	getServices() {
		return this.http.get(environment.apiBaseUrl + '/getServicceforDrpdown');
	}

//Getcategory
getfrontServices() {
	return this.http.get(environment.apiBaseUrl + '/service');
}


	
	// Get Countries
	getCountries() {
		return this.http.get(environment.apiBaseUrl + '/Countries');
	}

	// Get Contract Info For Service Seekar
	getServiceSeekarContract(data) {
		return this.http.post(environment.apiBaseUrl + '/getServiceSeekarContract', data);
	}

	// Get Contract Info For Service Provider
	getServiceProviderContract(data) {
		return this.http.post(environment.apiBaseUrl + '/getServiceProviderContract', data);
	}

	// Hire a service provider
	hireServiceProvider(jobdata) {
		return this.http.put(environment.apiBaseUrl + '/hire', jobdata);
	}

	// Rate and Review
	ratingReview(rateReview: any) {
		return this.http.post(environment.apiBaseUrl + '/ratereview', rateReview);
	}
	myjobsearch(typeword) {
		var userId = localStorage.getItem('id');
		var jobsearchdata = { "userId": userId, "role": typeword.role, "searchtext": typeword.searchtext, "pageSize": typeword.pageSize, "pageNo": typeword.pageNo };
		return this.http.post(environment.apiBaseUrl + '/myjobsearch', jobsearchdata);
	}

	myjobsearchpagination(data) {
		var userId = localStorage.getItem('id');
		var role = localStorage.getItem('role');
		var jobsearchdata = { "userId": userId, "role": role, "searchtext": data.searchtext, "pageSize": data.pageSize, "pageNo": data.pageNo };

		return this.http.post(environment.apiBaseUrl + '/myjobsearch', jobsearchdata);

	}

	invoicepdf(data)
	{
		return this.http.post(environment.apiBaseUrl + '/invoicepdf', data);
	}

	getInvoice(userid,jobid)
	{
            var invoicedata= {"userid":userid,"jobid":jobid};
		return this.http.post(environment.apiBaseUrl + '/getinvoice', invoicedata);
	}

	// get service providers

	getServiceproviders(pagedata)
	{
				return this.http.post(environment.apiBaseUrl + '/getserviceProvider', pagedata);
	}

	spsearch(userdata)
	{
		return this.http.post(environment.apiBaseUrl + '/spsearch', userdata);
	}

	getuserByCategory(userdata)
	{
		return this.http.post(environment.apiBaseUrl + '/spsearch', userdata);
	}

	changejobstatus(changeJobDate)
	{
		return this.http.post(environment.apiBaseUrl + '/changeJobDate', changeJobDate);
		
	}

	setjobstatus(changeJobDate)
	{
		return this.http.post(environment.apiBaseUrl + '/changeJobDate', changeJobDate);
		
	}

	setjobclientslatus(changeJobDate)
	{
		return this.http.post(environment.apiBaseUrl + '/SendstatusToapplyuser', changeJobDate);
	}

	
}