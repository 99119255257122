import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { UserService } from '../../shared/user.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { JobService } from '../../shared/job.service';
import { NgForm } from '@angular/forms';
import { ImageCropperComponent, CropperSettings } from 'ng2-img-cropper';
import { CropperOption } from 'ngx-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';

@Component({
	selector: 'app-admin-user',
	templateUrl: './admin-user.component.html',
	styleUrls: ['./admin-user.component.css']
})
export class AdminUserComponent implements OnInit {
	public cropperConfig: CropperOption;
	data: any;
	cropperSettings: CropperSettings;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	@ViewChild('cropper', undefined)
	cropper: ImageCropperComponent;
	userDetail: string;
	serverErrorMessages: boolean;
	baseurl: string;
	userprofile: string;
	// serverErrorMessages: string;
	showSucessMessage: boolean;
	countries: string;
	service: string;
	imageSrc: any;
	imagename: string;
	docfiles: any;
	banks: any;
	accountNumber: any;
	targetvalue:any;

	public uploaders: FileUploader = new FileUploader({
		url: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/api/uploaddociid', itemAlias: 'uploadId'
	});
	role: string;

	constructor(private JobService: JobService, private spinnerService: Ng4LoadingSpinnerService, private userService: UserService, private router: Router, private route: ActivatedRoute, private adminService: AdminService, public toastr: ToastrManager) {

		this.cropperSettings = new CropperSettings();
		this.cropperSettings.width = 70;
		this.cropperSettings.height = 70;
		this.cropperSettings.croppedWidth = 70;
		this.cropperSettings.croppedHeight = 70;
		this.cropperSettings.canvasWidth = 200;
		this.cropperSettings.canvasHeight = 200;
		this.cropperSettings.rounded = true;
		this.cropperSettings.touchRadius = 37;
		this.cropperSettings.touchRadius = 35;
		this.cropperSettings.dynamicSizing = true;
		this.cropperSettings.noFileInput = true;
		this.cropperSettings.cropperClass = "croppimag";
		this.data = {};

	}

	ngOnInit() {
		this.baseurl = environment.imgBaseUrl;
		let userId = this.route.snapshot.paramMap.get('id');
		this.adminService.getUserDetail(userId).subscribe(
			(res: any) => {
				this.userDetail = res.data;
				this.accountNumber = res.data.accountNo;
				localStorage.setItem("image", res.data.image);
				localStorage.setItem("docfiles", res.data.docfiles);
			});

		this.spinnerService.hide();
		console.log(localStorage.getItem('role'));
		this.role = localStorage.getItem('role');
		this.baseurl = environment.imgBaseUrl;
		this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			var filname = JSON.parse(response);
			this.docfiles = filname.filename;
			localStorage.setItem("docfile", this.docfiles);

		}

		this.JobService.getCountries().subscribe(
			(res: any) => {
				this.countries = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				// if (err.status == 422) {
				// 	this.serverErrorMessages = err.error.join('<br/ >');
				// } else
				// 	this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);

		this.JobService.getServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

		this.userService.getBanks().subscribe(
			(res: any) => {
				this.banks = res.data;
			}
		);
	}

	appro(event)
	{
	this.targetvalue = event.target.checked;

	}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}
	imageCropped(event: any) {

		this.data.image = event.base64;
	}
	imageLoaded() {
		// show cropper
	}
	loadImageFailed() {
		// show message
	}
	userapprove(status) {
		let userId = this.route.snapshot.paramMap.get('id');
		let userdata = { "userid": userId, "status": status };
	}

	onSubmit(form: NgForm) {
		this.spinnerService.show();
		// console.log(form.value);
		let userId = this.route.snapshot.paramMap.get('id');
		localStorage.setItem("userid", userId);
		if (this.data.image) {
			localStorage.setItem("image", this.data.image);
		}
		setTimeout(() => {
			this.userService.updateUser(form.value).subscribe(
				res => {
					this.toastr.successToastr('Successfully update!');
					// this.showSucessMessage = true;

					setTimeout(() => {
						this.router.navigate(['/admin/user']);
						this.spinnerService.hide();
					}, 3000);
				},
				err => {
					this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
					// if (err.status == 422) {
					// 	this.serverErrorMessages = err.error.join('<br/ >');
					// }
					// else
					// 	this.serverErrorMessages = 'Something went wrong. please contact admin.';
				}
			);
		}, 5000);
	}



	readURL(event): void {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;
			reader.readAsDataURL(file);
		}
	}

	fileChangeListener($event) {
		var image: any = new Image();
		var file: File = $event.target.files[0];
		var myReader: FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent: any) {
			image.src = loadEvent.target.result;
			that.cropper.setImage(image);

		};

		myReader.readAsDataURL(file);
	}

	mychange(val) {
		const self = this;
		let chIbn = val.toString().split('-').join('');
		if (chIbn.length > 0) {
			chIbn = chIbn.match(new RegExp('.{1,4}', 'g')).join('-');
		}
		console.log(chIbn);
		this.accountNumber = chIbn;
	}

}
