import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm,FormGroup, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AdminService } from '../../shared/admin.service';

import { CKEditorModule } from 'ngx-ckeditor';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.css']
})
export class EditPageComponent implements OnInit {
  @ViewChild("ckeditor") ckeditor: any;
  content: string;
  myModelChanged: string;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private route: ActivatedRoute, private router: Router, private adminService: AdminService, public toastr: ToastrManager) { 
    this.content = '<p>Old Text</p>';
   
    setTimeout(() => {  
      this.myModelChanged = '<p>Is the way feasible?</p>';
    }, 50);
  }
  pagedata:string;
  pageid:any;
  public editorValue: string = '';
  htmlContents:any;
  htmlContent:any;
  config: AngularEditorConfig = { editable: true, spellcheck: true, height: '15rem', minHeight: '5rem', placeholder: 'Enter text here...', translate: 'no' }


  ngOnInit() {
this.htmlContent ="This is test";

		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

    this.pageid = this.route.snapshot.paramMap.get('id');

    this.adminService.pagedetail(this.pageid).subscribe(
			(res: any) => {
				if (res.data) {
					this.pagedata = res.data[0];
					
				}
			}, err => {
				alert("error" + err);
			}
		);
  }
  onReady(): void {
    if(this.ckeditor)
    {
      this.content = this.myModelChanged;
      // or
      //this.ckeditor.instance.setData(this.myModelChanged);
    }
  }

// Edit page 
  onSubmit(form: NgForm) {
    this.spinnerService.show();
    let bankid = this.route.snapshot.paramMap.get('id');
    form.value.pageid = this.pageid;
    this.adminService.updatePagesdetail(form.value).subscribe(
    (res:any) => {

      this.router.navigate(['/admin/viewpages']);
  
          this.toastr.successToastr('Page update  Successfullly');
                }
    ,
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}


}
