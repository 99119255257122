// Built In Imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignUpTypeComponent } from './user/sign-up-type/sign-up-type.component';
import { appRoutes } from './routes';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';
import { SiteFooterComponent } from './_layout/site-footer/site-footer.component';
import { LoginHeaderComponent } from './_layout/login-header/login-header.component';
import { LoginFooterComponent } from './_layout/login-footer/login-footer.component';
import { LoginLayoutComponent } from './_layout/login-layout/login-layout.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { AdminDashboardComponent } from './user/admin-dashboard/admin-dashboard.component';
import { SignUpNiahComponent } from './user/sign-up-niah/sign-up-niah.component';
import { LogoutComponent } from './logout/logout.component';
import { JobpostComponent } from './job/jobpost/jobpost.component';
import { GetjobsComponent } from './job/getjobs/getjobs.component';
import { ViewjobComponent } from './job/viewjob/viewjob.component';
import { DashboardHeaderComponent } from './_layout/dashboard-header/dashboard-header.component';
import { DashboardLayoutComponent } from './_layout/dashboard-layout/dashboard-layout.component';
import { AdminDashboardHeaderComponent } from './_layout/admin-dashboard-header/admin-dashboard-header.component';
import { AdminDashboardLayoutComponent } from './_layout/admin-dashboard-layout/admin-dashboard-layout.component';
import { ProfileSettingComponent } from './user/profile/profile-setting/profile-setting.component';
import { EqualValidator } from './equal-validator.directive';
import { FileSelectDirective } from 'ng2-file-upload';
import { MatStepperModule, MatButtonModule, MatInputModule, MatRippleModule, MatFormFieldModule, MatCardModule, MatRadioModule, MatTabsModule, MatPaginatorModule, MatDialogModule, MatSidenavModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignUpStep2Component } from './user/sign-up-step2/sign-up-step2.component';
import { SignUpStep3Component } from './user/sign-up-step3/sign-up-step3.component';
import { SignUpStep4Component } from './user/sign-up-step4/sign-up-step4.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { VerifyUserComponent } from './user/verify-user/verify-user.component';
import { MyjobsComponent } from './job/myjobs/myjobs.component';
import { AdminUsersComponent } from './user/admin-users/admin-users.component';
import { AdminUserComponent } from './user/admin-user/admin-user.component';
import { ViewContractComponent, DialogForServiceProvider } from './job/view-contract/view-contract.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MycontractsComponent, DialogOverviewExampleDialog } from './job/mycontracts/mycontracts.component';
import { CountryManagementComponent } from './admin/country-management/country-management.component';
import { ServiceManagementComponent } from './admin/service-management/service-management.component';
import { JobManagementComponent } from './admin/job-management/job-management.component';
import { JobDetailComponent } from './admin/job-detail/job-detail.component';
import { ServiceComponent } from './admin/service/service.component';
import { EditCountryComponent } from './admin/edit-country/edit-country.component';
import { AddCountryComponent } from './admin/add-country/add-country.component';
import { NhFormatInputDirective } from './nh-format-input.directive';
import { AddbankComponent } from './admin/addbank/addbank.component';
import { BankManagementComponent } from './admin/bank-management/bank-management.component';
import { EditBankComponent } from './admin/edit-bank/edit-bank.component';
import { AddServiceComponent } from './admin/add-service/add-service.component';
import { EditServiceComponent } from './admin/edit-service/edit-service.component';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { CropperModule } from 'ngx-cropper';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { AgmCoreModule } from '@agm/core';
import { StaticPageManagementComponent } from './admin/static-page-management/static-page-management.component';

import { MessageComponent } from './message/message.component';
import { AdminMessageComponent } from './admin/admin-message/admin-message.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxEditorModule } from 'ngx-editor';
import { TermasConsitionsComponent } from './termas-consitions/termas-consitions.component';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ViewProfileComponent } from './user/profile/view-profile/view-profile.component';
import { ChartsModule } from "ng2-charts/ng2-charts";
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
  LinkedinLoginProvider
} from 'ng-social-login-module';
import { FindServiceProviderComponent } from './user/find-service-provider/find-service-provider.component';
import { ReportComponent } from './admin/report/report.component';
import { ViewpagesComponent } from './admin/viewpages/viewpages.component';
import { EditPageComponent } from './admin/edit-page/edit-page.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HomepageManageComponent } from './admin/homepage-manage/homepage-manage.component';
import { SliderModule } from 'angular-image-slider';
import { OwlModule } from 'ngx-owl-carousel';
import { HowitworksComponent } from './admin/sections/howitworks/howitworks.component';
import { AddhowitworkComponent } from './admin/sections/addhowitwork/addhowitwork.component';
import { EditHowitworkComponent } from './admin/sections/edit-howitwork/edit-howitwork.component';
import { TestimonialComponent } from './admin/sections/testimonial/testimonial.component';
import { AddtestimonialComponent } from './admin/sections/addtestimonial/addtestimonial.component';
import { EdittestimonialComponent } from './admin/sections/edittestimonial/edittestimonial.component';
import { EditTestimonialComponent } from './admin/sections/edit-testimonial/edit-testimonial.component';
import { InvoicesComponent } from './admin/invoices/invoices.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxCaptchaModule } from 'ngx-captcha';

import { EditorModule } from '@tinymce/tinymce-angular';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactListComponent } from './admin/contact-list/contact-list.component';
import { ShowratereviewComponent } from './admin/sections/showratereview/showratereview.component';
import { AllcategoriesComponent } from './pages/allcategories/allcategories.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { JwtInterceptor } from './shared/jwt.interceptor';

const CONFIG = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('155024839252-sc72ppeu96h6usaal8fpv6qin0nnu37p.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('541986746205033')
  },
  {
    id: LinkedinLoginProvider.PROVIDER_ID,
    provider: new LinkedinLoginProvider('LINKEDIN_CLIENT_ID')
  }
], true);

export function provideConfig() {
  return CONFIG;
}


@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SignUpComponent,
    SignUpTypeComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    LoginLayoutComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    SignUpNiahComponent,
    LogoutComponent,
    JobpostComponent,
    GetjobsComponent,
    ViewjobComponent,
    DashboardHeaderComponent,
    DashboardLayoutComponent,
    AdminDashboardComponent,
    AdminDashboardHeaderComponent,
    AdminDashboardLayoutComponent,
    ProfileSettingComponent,
    FileSelectDirective,
    EqualValidator,
    SignUpStep2Component,
    SignUpStep3Component,
    SignUpStep4Component,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyUserComponent,
    MyjobsComponent,
    AdminUsersComponent,
    AdminUserComponent,
    ViewContractComponent,
    MycontractsComponent,
    DialogOverviewExampleDialog,
    DialogForServiceProvider,
    CountryManagementComponent,
    ServiceManagementComponent,
    JobManagementComponent,
    JobDetailComponent,
    ServiceComponent,
    EditCountryComponent,
    AddCountryComponent,
    NhFormatInputDirective,
    AddbankComponent,
    BankManagementComponent,
    EditBankComponent,
    AddServiceComponent,
    EditServiceComponent,
    ImageCropperComponent,
    StaticPageManagementComponent,
    MessageComponent,
    AdminMessageComponent,
    TermasConsitionsComponent,
    InvoicesComponent,
    ViewProfileComponent,
    FindServiceProviderComponent,
    ReportComponent,
    ViewpagesComponent,
    EditPageComponent,
    FaqComponent,
    ChangePasswordComponent,
    AboutUsComponent,
    HomepageManageComponent,
    HowitworksComponent,
    AddhowitworkComponent,
    EditHowitworkComponent,
    TestimonialComponent,
    AddtestimonialComponent,
    EdittestimonialComponent,
    EditTestimonialComponent,
    ContactUsComponent,
    ContactListComponent,
    ShowratereviewComponent,
    AllcategoriesComponent,
    PrivacyPolicyComponent

  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    EditorModule,
    RouterModule.forRoot(appRoutes),
    ToastrModule.forRoot(),
    HttpClientModule,
    NgxCaptchaModule,
    MatStepperModule,
    AngularEditorModule,
    MatSidenavModule,
    MatButtonModule,
    OwlModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatCardModule,
    MatRadioModule,
    SliderModule,
    MatTabsModule,
    MatPaginatorModule,
    MatDialogModule,
    Ng4LoadingSpinnerModule.forRoot(),
    AngularFontAwesomeModule,
    CropperModule,
    ChartsModule,
    ShowHidePasswordModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtT9o94Rlv4mc7TF_AmbhKtTBOQRiFJGA'
    }),
    CKEditorModule,
    NgDatepickerModule,
    AngularEditorModule,
    NgxEditorModule,
    ImageCropperModule,
    SocialLoginModule,
    Ng2SearchPipeModule
  ],
  entryComponents: [DialogOverviewExampleDialog, DialogForServiceProvider],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: AuthServiceConfig, useFactory: provideConfig }],
  bootstrap: [AppComponent],


})
export class AppModule { }