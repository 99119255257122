import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { environment } from '../../../environments/environment';
import { JobService } from '../../shared/job.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AdminService } from '../../shared/admin.service';
@Component({
  selector: 'app-homepage-manage',
  templateUrl: './homepage-manage.component.html',
  styleUrls: ['./homepage-manage.component.css']
})
export class HomepageManageComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
  pagedata:any;
  imageSrc: any;
  howitworks:any;
	public uploaders: FileUploader = new FileUploader({
		url: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/api/updatehomepagecontent', itemAlias: 'bannerimage'
	});
  ngOnInit() {

		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }


    this.adminservice.gethowitworks().subscribe(
			(res:any) => {
				this.howitworks = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
	
			}
		);
    this.adminservice.gethomepagecontent().subscribe(
      (res:any)=>{
        this.pagedata = res.data[0];
        localStorage.setItem("homepageimage",this.pagedata.bannerImage);
      });
    
    this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			console.log('ImageUpload:uploaded:', item, status, response);
					localStorage.setItem("homepageimage", response);
		}
  }



  onSubmit(form: NgForm) {
    this.spinnerService.show();
    setTimeout(() => {
    this.adminservice.Updatehomepage(form.value).subscribe(
    (res:any) => {
      this.spinnerService.hide();
             this.toastr.successToastr('Update Successfullly');
             
          },
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}, 2000);
}


public imagePath;
imgURL: any;
public message: string;

preview(files) {
  if (files.length === 0)
    return;

  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }

  var reader = new FileReader();
  this.imagePath = files;
  reader.readAsDataURL(files[0]); 
  reader.onload = (_event) => { 
    this.pagedata.bannerImage = reader.result; 
    this.imgURL=reader.result;
  }
}
}
