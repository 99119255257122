import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { JobService } from "../../shared/job.service";
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';

@Component({
	selector: 'app-job-management',
	templateUrl: './job-management.component.html',
	styleUrls: ['./job-management.component.css']
})
export class JobManagementComponent implements OnInit {
	constructor(private toastr:ToastrManager,private router: Router,private JobService: JobService,private adminservice:AdminService) { }
	getalljobs: any;
	totaljobs: any;
	responsedata: string;
	viewdata: string;
	storevalue: string;
	services: any;
	recordnotfound: boolean;
	searchtext: string;
	pagedata: any;
	searchstring: any;
	optionsChecked = [];
	emptyarray = [];
	categoryset: any;
	categoryrecordmsg: any;
	baseurl:any;
	baseurljobpost :any;
	ngOnInit() {

this.baseurl = environment.pdfBaseUrl;
this.baseurljobpost = environment.imgBaseUrl;


console.log(this.baseurl);

var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

		localStorage.setItem("category", "");
		localStorage.setItem("searchtext", "");
		localStorage.setItem("sortorder", "");
		this.searchtext = "";
		var pagedata = { pageNo: 0, pageSize: 10 };
		this.adminservice.getAlljobs(pagedata).subscribe(
			(res: any) => {
				console.log("res===>",res)
				if (res) {
					this.getalljobs = res.data.joblist;
					this.totaljobs = res.data.total;

				}
			},
			err => {
				alert("Error" + err);
			}
		);

		this.JobService.getServices().subscribe(
			(res: any) => {
				this.services = res.data;
				for (let service of this.services) {
					this.optionsChecked.push(service._id)
				}
			},
			err => {
				alert('Error' + err);
			}
		);

	}
	changePage(event: any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
		let data = {
			pageNo: pageNo,
			pageSize: pageSize
		}
		this.adminservice.getAlljobs(data).subscribe(
			(res: any) => {
				this.getalljobs = res.data.joblist;
				this.totaljobs = res.data.total;
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	}

	// Getjobs by category
	getJobByCategory(category, event) {
		this.services[category] = event.target.checked;
		if (event.target.checked) {
			this.optionsChecked.push(category);
		}
		else {
			if (this.optionsChecked.includes(category)) {
				this.optionsChecked.splice(this.optionsChecked.indexOf(category), 1);
			}
		}


		this.searchstring = "search";
		localStorage.setItem('category', JSON.stringify(this.optionsChecked));
		this.pagedata = { pageNo: 0, pageSize: 10, category: JSON.stringify(this.optionsChecked) };
		this.JobService.getJobByCategory(this.pagedata).subscribe(
			(res: any) => {
				if (res.docs) {
					this.getalljobs = res.docs;
					this.totaljobs = res.total;
					console.log(res.recordCount);
					this.categoryset = res.service;
				}
				if (this.totaljobs < 1) {
					this.categoryrecordmsg = true;
				}
				else {

					this.categoryrecordmsg = false;
				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}
	//jobsearch
	jobsearch() {
		this.searchstring = "search";
		localStorage.setItem("searchtext", this.searchtext);
		this.pagedata = { pageNo: 0, pageSize: 10, 'searchtext': this.searchtext, "role": localStorage.getItem('role') };
		this.JobService.myjobsearch(this.pagedata).subscribe(
			(res: any) => {
				if (res.data) {
					this.getalljobs = res.data.joblist;
					this.totaljobs = res.data.total;
				//	this.categoryset = res.data.service;
				}
				if (this.totaljobs < 1) {
					this.recordnotfound = true;
				}
				else {
					this.recordnotfound = false;
				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}
// page change
	changePageAftersearch(event: any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
		var searchtext = localStorage.getItem("searchtext");
			let data = {
			pageNo: pageNo,
			pageSize: pageSize,
			"searchtext": searchtext,
		}

		this.adminservice.jobsearchpagination(data).subscribe(
			(res: any) => {
				this.getalljobs = res.data.joblist;
				this.totaljobs = res.data.total;
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	}

	// change admin status
	changeadminstatus(id,event)
	{

		if(event.target.checked)
		{
    var status= "active";
		}
		else{

			var status ="deactive";
		}
		this.adminservice.changeadminstatus(id,status).subscribe(
			(res: any) => {
				this.toastr.successToastr(res.message, 'Success');
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	
	}


}
