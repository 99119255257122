import { Component, OnInit } from '@angular/core';
import { JobService } from '../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-viewpages',
  templateUrl: './viewpages.component.html',
  styleUrls: ['./viewpages.component.css']
})
export class ViewpagesComponent implements OnInit {

  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
  pages:any;
    ngOnInit() {
      var role =  localStorage.getItem('role');
      if(role =="jobSeekar")
      {
      this.router.navigate(['/findwork']);
      } 
      if(role == "jobProvider")
      {
        this.router.navigate(['/jobpost']);
      }
  
      this.adminservice.getPages().subscribe(
        (res:any) => {
          this.pages = res.data;
        
        },
        err => {
          this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
    
        }
      );
    }

    deletePage(pageid)
	{
		if(confirm("Are you sure to delete")) {


		this.adminservice.deletePage(pageid).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Delete Successfullly');
					 
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

		this.router.navigate(['/admin/viewpages']);
	}


else

{
	return false;
}

}

}
