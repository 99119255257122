import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-termas-consitions',
  templateUrl: './termas-consitions.component.html',
  styleUrls: ['./termas-consitions.component.css']
})
export class TermasConsitionsComponent implements OnInit {

  constructor(private userService: UserService) { }
pagedata:any;
  ngOnInit() {
    this.userService.getpage('5ca1bbd469c50128771fab84').subscribe(
			(res:any) => {
				this.pagedata = res.data[0];

			},
			err => {
      }
		);
  }

}
