import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { JobService } from '../../shared/job.service';
import { MessagesService } from '../../shared/messages.service';
import { environment } from '../../../environments/environment';

export interface DialogData {
	rating: number;
	comment: string;
}

@Component({
	selector: 'app-view-contract',
	templateUrl: './view-contract.component.html',
	styleUrls: ['./view-contract.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ViewContractComponent implements OnInit {

	contractInfo: any;
	serviceSeekar: any;
	serviceProvider: any;
	rateReview: any;
	yourRating: any;
	anotherRating: any;
	activeJobsCount: number = 0;
	completedJobsCount: number = 0;
	status: string;
	baseurl: string;
	readMoreLess: Number = 0;
	rating: number = 1;
	comment: string;
	yourRatingDone: number = 0;
	anotherRatingDone: number = 0;
	jobproviderrate: Number;
	jobseeakrrate: Number;
	services: any;
	role: any;
	userId: String;
	messageText: String;
	receiverId: String;
	jobId: String;
	filename: String;
	pdfgenerate: boolean;
	invoicedata: any;
	chatStartOrNot: Number = 0;
	chatId: string = '';
	// messagesService:any;

	constructor(private jobService: JobService, private router: Router, private route: ActivatedRoute, private toastr: ToastrManager, public dialog: MatDialog, private messagesService: MessagesService) { }

	ngOnInit() {
		if (localStorage.getItem("token") == null) {
			this.router.navigate(['/login']);
		}
		this.jobId = this.route.snapshot.paramMap.get('id')
		this.userId = localStorage.getItem("id")
		this.baseurl = environment.pdfBaseUrl;
		this.getContract();
		this.messagesService.joinRoom({ senderId: this.userId });

		this.jobService.getInvoice(this.userId, this.jobId).subscribe(
			(res: any) => {
				this.invoicedata = res.data;
				if (this.invoicedata[0]) {
					this.pdfgenerate = true;
					this.filename = this.invoicedata[0].pdfFile;
				}
				else {
					this.pdfgenerate = false;
				}
			},
			err => {
				alert('Error' + err);
			}
		);
	}

	generatePDF() {
		var pdfgenrate = { "jobid": this.jobId, "userid": this.userId };
		this.jobService.invoicepdf(pdfgenrate).subscribe((res: any) => {
			this.toastr.successToastr('Success');
			this.filename = res.pdfname;
			this.pdfgenerate = true;
		},
			(err: any) => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
			}
		);
	}
	getContract() {
		this.activeJobsCount = 0;
		var data = {
			jobId: this.route.snapshot.paramMap.get('id'),
			userId: localStorage.getItem("id")
		}

		this.jobService.getServices().subscribe(
			(res: any) => {
				this.services = res.data;
			},
			err => {
				alert('Error' + err);
			}
		);

		this.jobService.getServiceProviderContract(data).subscribe(
			(res: any) => {
				if (res.jobDetails && res.jobDetails[0]) {
					for (let singleJob of res.jobDetails[0].jobsdes) {
						if ((singleJob.status == 'hired' || singleJob.status == 'completed') && singleJob.userId == localStorage.getItem("id"))
							this.status = res.jobDetails[0].status;
					}
					this.contractInfo = res.jobDetails[0];
					this.serviceSeekar = res.serviceSeekar && res.serviceSeekar[0];
					res.serviceSeekar && res.serviceSeekar[0].jobsDetails.forEach(element => {
						if (element.status == 'active' || element.status == 'hired')
							++this.activeJobsCount;
					});
					this.serviceProvider = res.serviceProvider && res.serviceProvider[0];


					res.serviceProvider && res.serviceProvider[0].jobsDetails.forEach(element => {
						if (element.status == 'completed')
							++this.completedJobsCount;
					});
					this.rateReview = res.rateReview;
					if (res.jobproviderrates) {
						//Job provider rates
						var jprates = res.jobproviderrates;
						var jpotal = Object.keys(res.jobproviderrates).length;
						var startnumber = 0;
						for (let starrating of jprates) {
							startnumber += Number(starrating.rating);
						}
						this.jobproviderrate = Math.round(startnumber / jpotal);
						//job seekar rates
						var jsrates = res.jobseekarrates;
						var jsotal = Object.keys(jsrates).length;
						var startnumber = 0;
						for (let starrating of jsrates) {
							startnumber += Number(starrating.rating);
						}
						this.jobseeakrrate = Math.round(startnumber / jsotal);
						var jsotal = Object.keys(jsrates).length;
						var startnumber = 0;
						for (let starrating of jsrates) {
							startnumber += Number(starrating.rating);
						}
						this.jobseeakrrate = Math.round(startnumber / jsotal);
					}
					res.rateReview && res.rateReview.forEach(element => {
						if (element.userFromId == localStorage.getItem("id")) {
							this.yourRating = element;
							this.yourRatingDone = 1;
						} else {
							this.anotherRating = element;
							this.anotherRatingDone = 1;
						}
					});
				} else {
					this.toastr.warningToastr('No contract found.', 'Warning');
					setTimeout(() => {
						this.router.navigate(['/myjobs']);
					}, 5000);
				}
				var data = {
					user1: this.serviceSeekar._id,
					user2: localStorage.getItem("id"),
					jobId: this.jobId
				}
				this.messagesService.checkChat(data).subscribe(
					(result) => {
						if (result) {
							this.chatStartOrNot = 1;
							this.chatId = result['_id'];
						}
					},
					(error) => {
						this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
					}
				);
			},
			(error: any) => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
			}
		)
	}

	getReadLess(type: number) {
		this.readMoreLess = type;
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(DialogForServiceProvider, {
			width: '250px',
			data: { rating: this.rating, comment: this.comment }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result != 'close') {
				var data = {
					rating: result.rating,
					comment: result.comment,
					jobId: this.route.snapshot.paramMap.get('id'),
					userFromId: localStorage.getItem("id"),
					userToId: this.serviceSeekar._id
				}
				this.jobService.ratingReview(data).subscribe(
					(res: any) => {
						this.getContract();
						this.toastr.successToastr(res['status'], 'Success');
					},
					(err: any) => {
						this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
					}
				)
			}
		});
	}

	startChat() {
		localStorage.setItem("chatId", this.chatId);
		this.router.navigate(['/message']);
	}
}

@Component({
	selector: 'dialog-for-service-provider',
	templateUrl: 'dialog-for-service-provider.html',
})
export class DialogForServiceProvider {

	public ownerForm: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<DialogForServiceProvider>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	ngOnInit() {
		this.ownerForm = new FormGroup({
			comment: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(1000)])
		});
	}

	onNoClick(type: any): void {
		this.dialogRef.close(type);
	}

	closeDialog = (data: any) => {
		this.dialogRef.close(data);
	}

	setRating(rate: number) {
		this.data.rating = rate;
	}



}