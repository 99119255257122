import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from '../../shared/user.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
	constructor(public toastr: ToastrManager,private spinnerService: Ng4LoadingSpinnerService,private userService: UserService, private http: HttpClient, private location: Location, private route: ActivatedRoute, private router: Router) { }


  ngOnInit() {
    this.spinnerService.hide();

  }

  onSubmit(form: NgForm) {
    this.spinnerService.show();

    this.userService.changePasswords(form.value).subscribe(
			(res:any) => {
        if(res.status == false)
        {
          this.toastr.errorToastr('Old password is not correct');
        }
        else
        {
        this.toastr.successToastr('Password change Successfully!');
        }
				// this.showSucessMessage = true;
				
				setTimeout(() => {
				//	this.router.navigate(['/dashboard']);
				//	this.spinnerService.hide();
				}, 3000);
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				// if (err.status == 422) {
				// 	this.serverErrorMessages = err.error.join('<br/ >');
				// }
				// else
				// 	this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);



				// this.userService.changepassword(form.value).subscribe(
				// res:any => {
        //   this.toastr.successToastr('Success!');
				// 	this.spinnerService.hide();
		
				// 	this.router.navigate(['/signup-step2']);
        //     },
        //     err =>{
				// 			;				
				// 		}
					
				// );	


	
          }
}
