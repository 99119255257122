import { Component, OnInit } from '@angular/core';
import { ChartsModule } from "ng2-charts/ng2-charts";
import { AdminService } from '../../shared/admin.service';
import { ExportToCsv } from 'export-to-csv';
import * as CanvasJS from 'canvasjs/dist/canvasjs.min';


import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels;
  public barChartType;
  public barChartLegend;
  public barChartData;

  public barChartLabels1;
  public barChartType1;
  public barChartLegend1;
  public barChartData1;
  
  active:any;
  completed:any;
  hired:any;
  userReports:any;

  uapp1:any;
  verify0:any;
  uapp0:any;
  userReports1:any;
  userReports0:any
  juser:any;
  jdata:any;

  constructor(private adminService:AdminService,private router: Router) { }

   ngOnInit() {
    this.active= 0;
    this.completed= 0;
    this.hired= 0;
    this.barChartLabels1 = ['', '', ''];
    this.barChartType1 = 'bar';
    this.barChartLegend1 = true;

    this.uapp1=0;
    this.verify0=0;
    this.uapp0=0;
    this.userReports1=0;
    this.userReports0=0;
    this.juser=0;
    this.jdata=0
    this.adminService.getmonhtlyUsers().subscribe(
      (res: any) => {
        if(res.data[0])
        {
    
        this.userReports1 = res.data;
        }
              
        if(res.uapp[1])
        {
        this.uapp1=res.uapp[1].count;
          }

          if(res.verify[1])
        {
        this.verify0=res.verify[1].count;
          }

          if(res.uapp[0])
        {
        this.uapp0=res.uapp[0].count;
          }

          if(this.userReports1[1])
        {
        this.userReports0=this.userReports1[1].count;
          }

          if(this.userReports1[2])
        {
        this.userReports1=this.userReports1[2].count;
          }

          if(res.juser)
        {
        this.juser=res.juser;
          }
          if(res.jdata[0])
          {
          this.jdata=res.jdata[0].count;
            }

        this.barChartData1 = [
          {data: [this.uapp1], label: 'Approved user'},
          {data: [this.verify0], label: 'Verified user'},
          {data: [this.uapp0], label: 'Inactive user'},
          {data: [this.userReports0], label: 'Jobseekar'},
          {data: [this.userReports1], label: 'Jobprovider'},
          {data: [this.juser], label: 'Jobapply user'},
          {data: [this.jdata], label: 'Hired user'},

               ];
      });


      this.barChartData1 = [
        {data: [12] ,label: ''},
        {data: [22], label: ''},
        {data: [33], label: ''},
        {data: [67], label: ''},
        {data: [76], label: ''},
        {data: [67], label: ''},
        {data: [676], label: ''},
      ];
 

    var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }
    this.barChartLabels = ['', '', ''];
    this.barChartType = 'bar';
    this.barChartLegend = true;

    this.adminService.getuserReports().subscribe(
      (res: any) => {
        this.userReports = res.data;
      
        this.barChartData = [
          {data: [res.uapp[1].count], label: 'Approved user'},
          {data: [res.verify[1].count], label: 'Verified user'},
          {data: [res.uapp[0].count], label: 'Inactive user'},
          {data: [this.userReports[1].count], label: 'Jobseekar'},
          {data: [this.userReports[2].count], label: 'Jobprovider'},
          {data: [res.juser], label: 'Jobapply user'},
          {data: [res.jdata[0].count], label: 'Hired user'},

               ];

         });
         




         this.barChartData = [
          {data: [12] ,label: ''},
          {data: [22], label: ''},
          {data: [33], label: ''},
          {data: [67], label: ''},
          {data: [76], label: ''},
          {data: [67], label: ''},
          {data: [676], label: ''},
        ];



this.adminService.getjobReports().subscribe(
  (res: any) => {

    res.data.forEach(obj => {
     
      if(obj._id == "active")
      {
        this.active= obj.count;
      }

      if(obj._id == "completed")
      {
        this.completed = obj.count;
      }

      if(obj._id == "hired")
      {
        this.hired = obj.count;
      }

     })
 
        // this.completed= res.data[1].count;
        // this.hired= res.data[2].count;
  let chart = new CanvasJS.Chart("chartContainer1", {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    title:{
      text: "Jobs"
    },
    data: [{
      type: "pie",
      showInLegend: true,
      toolTipContent: "<b>{name}</b>: {y} (#percent%)",
      indexLabel: "{name} - #percent%",
      dataPoints: [
        { y: this.active, name: "Active" },
        { y: this.completed, name: "Completed" },
        { y: this.hired, name: "Hired" }
  
      ]
    }]
  });
    
  chart.render();
  
  });

  this.adminService.getmonhtlyjobReports().subscribe(
    (res: any) => {
  

    res.data.forEach(obj => {
     
      if(obj._id == "active")
      {
        this.active= obj.count;
      }

      if(obj._id == "completed")
      {
        this.completed = obj.count;
      }

      if(obj._id == "hired")
      {
        this.hired = obj.count;
      }

     });

    let chart1 = new CanvasJS.Chart("chartContainer2", {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: true,
      title:{
        text: "Current Month Jobs"
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "<b>{name}</b>: {y} (#percent%)",
        indexLabel: "{name} - #percent%",
        dataPoints: [
          { y: this.active, name: "Active" },
          { y: this.completed, name: "Completed" },
          { y: this.hired, name: "Hired" }
    
        ]
      }]
    });
      
    chart1.render();
    
    });



  
 

}

exportcsv()
{

  this.adminService.getjobReports().subscribe(
      (res: any) => {
 var datas =[{"Active Job":res.data[1].count},{"Completed Job":res.data[1].count},{"Hired Job":res.data[2].count}];

  console.log(datas);
  const options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
 
const csvExporter = new ExportToCsv(options);

csvExporter.generateCsv([{"Active Job":res.data[0].count},{"Completed Job":res.data[1].count},{"Hired Job":res.data[2].count}]);

})
}

}