import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { UserService } from '../../shared/user.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  constructor(private userService: UserService) { }
  pagedata:any;
  ngOnInit() {

    this.userService.getpage('5c9c78e435c7c943c32f522a').subscribe(
			(res:any) => {
				this.pagedata = res.data[0];

			},
			err => {
      }
		);
  }

}
