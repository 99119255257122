import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap, RoutesRecognized } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Authentication } from '../../shared/authentication.model'

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

	showSucessMessage: boolean;
	serverErrorMessages: string;

	constructor(private route: ActivatedRoute, private userService: UserService, private http: HttpClient, private router: Router) { }

	ngOnInit() {

	}

	onSubmit(form: NgForm) {
		var userid = this.route.snapshot.paramMap.get('id');
		var userdata = { 'password': form.value.password, 'userid': userid };
		this.userService.resetpassword(userdata).subscribe(
			res => {
				this.showSucessMessage = true;
				setTimeout(() => this.showSucessMessage = false, 4000);
				this.resetForm(form);
			},
			err => {
				if (err.status == 422) {
					this.serverErrorMessages = err.error.join('<br/ >');
				}
				else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);
	}

	resetForm(form: NgForm) {
		// this.userService.selectedUser = {
		// 	fullName: '',
		// 	email: '',
		// 	password: ''
		// };
		form.resetForm();
		this.serverErrorMessages = '';
	}
}