import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { UserService } from '../../shared/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {DomSanitizer} from '@angular/platform-browser';
import { JobService } from '../../shared/job.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.css']
})
export class SiteFooterComponent implements OnInit {

  constructor(private JobService:JobService,private domSanitizer: DomSanitizer,private toastr:ToastrManager,private adminservice:AdminService,private Userservice:UserService) { }
  pagedata:any;
  public imagesUrl;
  joinupperfooterarea: any;
  jointitle:any;
  mainfooter:any;
  baseurl:any;
  login:any;
  userole:any;
  service:any;
  toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
}
  ngOnInit() {

    this.JobService.getServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

  if (localStorage.getItem("token") == null)
 {
  this.login=false;
  this.userole = localStorage.getItem('role');
 }

 localStorage.getItem('role')
    this.baseurl =environment.imgBaseUrl;
      
        this.adminservice.pagedetail('5cade28d30ba7e4608850880').subscribe(
          (res: any) => {
            if (res.data) {
             this.joinupperfooterarea = res.data[0].pageContent;
             this.jointitle = res.data[0].PageTitle;
                      }
          }, err => {
            alert("error" + err);
          }
        );

        this.adminservice.pagedetail('5cade4aca4bcf946e97d8ecb').subscribe(
          (res: any) => {
            if (res.data) {
             this.mainfooter = res.data[0].pageContent;
            }
          }, err => {
            alert("error" + err);
          }
        );

        
        
  }

  
}
