import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {

  login:boolean;
  role:any;
  constructor() { }

  ngOnInit() {

    this.role = localStorage.getItem("role");
    
if (localStorage.getItem("token") == null)
 {

  this.login=false;
  

 }

 else
 {


  this.login =true;
 }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
}

}
