import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobService } from '../../shared/job.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-jobpost',
	templateUrl: './jobpost.component.html',
	styleUrls: ['./jobpost.component.css'],
	providers: [JobService],
	encapsulation: ViewEncapsulation.None
})

export class JobpostComponent implements OnInit {

	baseurl: any;
	docfiles: any;
	service: string;
	countries: any;
	myDate: any;
	htmlContents: any;
	options: DatepickerOptions = {
		minYear: 2019,
		maxYear: 2030,
		displayFormat: 'MMM D YYYY',
		barTitleFormat: 'MMMM D YYYY',
		dayNamesFormat: 'dd',
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		minDate: new Date(Date.now()), // Minimal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
		fieldId: 'expirejob',
		// ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	};

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '25rem',
		minHeight: '5rem',
		placeholder: 'Enter text here...',
		translate: 'yes',
		uploadUrl: 'v1/images' // if needed,

	};

	public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl + '/jobpost', itemAlias: 'jobpostdoc'
	});
	constructor(private spinnerService: Ng4LoadingSpinnerService, private JobService: JobService, private router: Router, public toastr: ToastrManager) { }
	ngOnInit() {
		localStorage.setItem("docfile", "");
		this.baseurl = environment.pdfBaseUrl;
		this.htmlContents = "";
		if (localStorage.getItem("token") == null) {
			this.router.navigate(['/login']);
		}

		this.JobService.getServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

		this.JobService.getCountries().subscribe(
			(res: any) => {
				this.countries = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				// if (err.status == 422) {
				// 	this.serverErrorMessages = err.error.join('<br/ >');
				// } else
				// 	this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);


		// image upload

		this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			var filname = JSON.parse(response);
			this.docfiles = filname.filename;

			localStorage.setItem("docfile", this.docfiles);
		}


	}

	onSubmit(form: NgForm) {
		console.log("called job post")
		this.spinnerService.show();
		setTimeout(() => {
			console.log(form)
			// form.selectedUser = {
			// 	firstName: localStorage.getItem("firstName") ? localStorage.getItem("firstName") : '',
			// 	lastName: localStorage.getItem("lastName") ? localStorage.getItem("lastName") : '',
			// 	email: localStorage.getItem("email") ? localStorage.getItem("email") : '',
			// 	phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : '',
			// 	country: localStorage.getItem("country") ? localStorage.getItem("country") : '',
			// 	street: localStorage.getItem("street") ? localStorage.getItem("street") : '',
			// 	houseNo: localStorage.getItem("houseNo") ? localStorage.getItem("houseNo") : '',
			// 	area: localStorage.getItem("area") ? localStorage.getItem("area") : '',
			// 	serviceType: localStorage.getItem("serviceType") ? localStorage.getItem("serviceType") : '',
			// 	references: localStorage.getItem("references") ? localStorage.getItem("references") : '',
			// 	serviceTypeId: localStorage.getItem("serviceTypeId") ? localStorage.getItem("serviceTypeId") : '',
			// 	selectBank: localStorage.getItem("selectBank") ? localStorage.getItem("selectBank") : '',
			// 	accountNo: localStorage.getItem("accountNo") ? localStorage.getItem("accountNo") : '',
			// 	password: localStorage.getItem("password") ? localStorage.getItem("password") : '',
			// 	conpassword: localStorage.getItem("conpassword") ? localStorage.getItem("conpassword") : '',
			// 	imagename: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/uploads/' + localStorage.getItem("image"),
			// 	baseurl: '',
			// 	docid: '',
			// 	image: '',
			// 	userid: ''
			// };
			form.value.street = localStorage.getItem("street") ? localStorage.getItem("street") : '',
			form.value.houseNo = localStorage.getItem("houseNo") ? localStorage.getItem("houseNo") : '',
			form.value.area = localStorage.getItem("area") ? localStorage.getItem("area") : '',
			form.value.docfile = localStorage.getItem("docfile");
			this.JobService.jobPost(form.value).subscribe(
				(res: any) => {
					this.spinnerService.hide();
					this.toastr.successToastr(res.message, 'Success');
					this.resetForm(form);
				},
				err => {
					if (err.status == 422)
						this.toastr.errorToastr(err.error.message, 'Error');
					else
						this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
				}
			);
		}, 5000);
	}

	resetForm(form: NgForm) {
		this.JobService.selectedjob = {
			jobtitle: '',
			category: '',
			expirejob: '',
			location: '',
			jobdescription: '',
			additionalinfo: '',
			feature: ''
		};
		form.resetForm();
		form.controls.category.setValue('');
	}
}