// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiBaseUrl: 'http://localhost:3000/api',
	imgBaseUrl: 'http://localhost:3000/uploads',
	pdfBaseUrl: 'http://localhost:3000/invoicepdf',
	// apiBaseUrl: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/api',

	// imgBaseUrl: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/uploads',

	// pdfBaseUrl: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/invoicepdf'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

