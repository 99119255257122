import { Component, OnInit } from '@angular/core';

import { JobService } from '../../shared/job.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent implements OnInit {
  srvimg:any;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { } 
  public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl+'/admin/service', itemAlias: 'serviceimage'
  });
  
  serviceImage:any;
  ngOnInit() {
    var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

    this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			console.log('ImageUpload:uploaded:', item, status, response);
					localStorage.setItem("serviceImage", response);
		}
  }
  onSubmit(form: NgForm) {
    alert(form.value.serviceImage);
    this.spinnerService.show();
    setTimeout(() => {
    form.value.serviceimg = localStorage.getItem("serviceImage");
    this.adminservice.addServices(form.value).subscribe(
    (res:any) => {
      this.spinnerService.hide();
         if(res.already)
         {
          this.toastr.errorToastr('Service already exsist');
         }
         else
         {
      this.toastr.successToastr('Service Add Successfullly');
      this.router.navigate(['/admin/service-management']);
         }
    },
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}, 2000);
}

public imagePath;
imgURL: any;
public message: string;

preview(files, event) {

  if (files.length === 0)
    return;
  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }
  var reader = new FileReader();
  const img = new Image();
  img.src = window.URL.createObjectURL( files[0] );
  this.imagePath = files;
  reader.readAsDataURL(files[0]); 
  reader.onload = (_event) => { 
  const width = img.naturalWidth;

if(width > 400)
{
  alert("Please select image 400 X 400");
this.srvimg ="";
  return false;
}
    this.imgURL=reader.result;
    this.srvimg = reader.result;
  }
}
}
