import { Component, OnInit } from '@angular/core';
import { JobService } from '../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.css']
})
export class AddCountryComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }

  ngOnInit() {
    
    var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }
  }

  onSubmit(form: NgForm) {
    this.spinnerService.show();
    this.adminservice.addcountry(form.value).subscribe(
    (res:any) => {
         if(res.already)
         {
          this.spinnerService.hide();
          this.toastr.errorToastr('Country already exsist');
          
         }
         else
         {
          this.spinnerService.hide();
      this.toastr.successToastr('Country Add Successfullly');
      this.router.navigate(['/admin/country-management']);
         }
    },
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}
}
