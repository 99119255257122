import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { AdminService } from '../../shared/admin.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TargetLocator } from 'selenium-webdriver';
import { trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css']
})
export class EditServiceComponent implements OnInit {
servicedata:any;
baseurl:any;
servicimg:any;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private route: ActivatedRoute,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
  public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl+'/admin/service', itemAlias: 'serviceimage'
  });
  nimg:any;
  ngOnInit() {
    this.servicimg ="";

this.baseurl = environment.imgBaseUrl;
		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }


    this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			console.log('ImageUpload:uploaded:', item, status, response);
					localStorage.setItem("serviceImage", response);
		}
    let serviceid = this.route.snapshot.paramMap.get('id');

    this.adminservice.servicedetail(serviceid).subscribe(
			(res: any) => {
				if (res.data) {
					this.servicedata = res.data[0];
          localStorage.setItem("serviceImage", this.servicedata.serviceImage);
				}
			}, err => {
				alert("error" + err);
			}
		);
  }

  disableshow(event)
  {
var v = event.trigger.value;
alert(v);
  }
  onSubmit(form: NgForm) {
    this.spinnerService.show();

    setTimeout(() => {
    form.value.serviceimg = localStorage.getItem("serviceImage");
    let serviceid = this.route.snapshot.paramMap.get('id');
    form.value.serviceid = serviceid;
    this.adminservice.updateService(form.value).subscribe(
    (res:any) => {
      this.spinnerService.hide();
      this.router.navigate(['/admin/service-management']);
  
          this.toastr.successToastr('Country update  Successfullly');
                }
    ,
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}, 8000);
}

public imagePath;
imgURL: any;

public message: string;

preview(files) {
  if (files.length === 0)
    return;
    var reader = new FileReader();
    const img = new Image();
   
    img.src = window.URL.createObjectURL( files[0] );
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
    const width = img.naturalWidth;
  
  if(width > 400)
  {
    alert("Please select image 400 X 400");
    this.nimg ="d";
    this.servicimg="";
    this.imgURL = "";
   return;
  }
  else{
    this.imagePath = files;
    this.imgURL=reader.result;
  }
   
  }
}

}
