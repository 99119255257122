import { Component, OnInit } from '@angular/core';
import { AdminService } from './../shared/admin.service';
import { UserService } from './../shared/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import {DomSanitizer} from '@angular/platform-browser';
import { JobService } from './../shared/job.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( private JobService: JobService,private domSanitizer: DomSanitizer,private toastr:ToastrManager,private adminservice:AdminService,private Userservice:UserService) { }
  pagedata:any;
  public imagesUrl;
  aboutniah: any;
  howitworks:any;
  baseurl:any;
  helsection:any;
helptitle:any;
abouttitle:any;
service:any;
  title = 'OwlCarousel2 in Angular7 with Custom Navigation Arrows';
 clients:any;
 images = [];
  carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 3,
        nav: true,
        loop: false
      },
      1500: {
        items: 3,
        nav: true,
        loop: false
      }
    }
  }
 
  toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
}
  ngOnInit() {

    this.JobService.getfrontServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

    this.baseurl ="http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/uploads";
    this.adminservice.gethowitworks().subscribe(
			(res:any) => {
				this.howitworks = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
	
			}
		);
    this.imagesUrl = [
      'assets/images/furniture-assembly.jpg',
      'assets/images/furniture-assembly.jpg',
      'assets/images/furniture-assembly.jpg',
      'assets/images/home-renovation.jpg',
      'assets/images/home-renovation.jpg'
      ];
    this.adminservice.gethomepagecontent().subscribe(
      (res:any)=>{
        this.pagedata = res.data[0];
        localStorage.setItem("homepageimage",this.pagedata.bannerImage);
      });
      this.Userservice.getClients().subscribe(
        (res: any) => {
          if (res.data != null) {
            this.clients = res.data;
            this.clients.forEach(item => {
      
               this.images.push({'text':item.comment,'name':item.firstName,'image':item.image,'rating':item.rating});

    
            });
          } 

        });

        this.adminservice.pagedetail('5c9c78e435c7c943c32f522a').subscribe(
          (res: any) => {
            if (res.data) {
             this.aboutniah = res.data[0].pageContent;
            this.abouttitle =  res.data[0].PageTitle;
            }
          }, err => {
            alert("error" + err);
          }
        );

        this.adminservice.pagedetail('5caddf6030ba7e4608850873').subscribe(
          (res: any) => {
            if (res.data) {
             this.helsection = res.data[0].pageContent;
            this.helptitle = res.data[0].PageTitle;
            }
          }, err => {
            alert("error" + err);
          }
        );

        
  }

  
}
