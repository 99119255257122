import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css']
})
export class LoginHeaderComponent implements OnInit {
nologin:string;
login:string;

  constructor(private router:Router) { }

  ngOnInit() {

    if (localStorage.getItem("token") != null) {
			if (localStorage.getItem('role') == "admin") {
				this.router.navigate(['/admin/message']);
			} else if (localStorage.getItem('role') == "jobSeekar") {
				this.router.navigate(['/findwork']);
			} else {
				this.router.navigate(['/jobpost']);
			}
		}

if (localStorage.getItem("token") == null)
		 {

		  this.nologin = 'notlogin';

		   }

		   else

		   {
                  this.login = 'login';

		   }

 }

  }


