import { Component, OnInit,ViewChild } from '@angular/core';

import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/user.service';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { JobService } from '../../shared/job.service';

import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import { CropperOption } from 'ngx-cropper';

import {ImageCropperComponent, CropperSettings} from 'ng2-img-cropper';

import { ImageCropperModule } from 'ngx-image-cropper';

@Component({
	selector: 'app-sign-up-step3',
	templateUrl: './sign-up-step3.component.html',
	styleUrls: ['./sign-up-step3.component.css']
})
export class SignUpStep3Component implements OnInit {

	public cropperConfig: CropperOption;
	data: any;
	cropperSettings: CropperSettings;

	imageChangedEvent: any = '';
	croppedImage: any = '';



@ViewChild('cropper', undefined)
cropper:ImageCropperComponent;

	public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl+'/uploaddociid', itemAlias: 'uploadId'
	},

	);

	imageSrc: any;
	gtelocalstorgae: string;
	firststep: string;
	formstep3: string;
	imagename: string;
	docfile: string;
	fileName: string;
	service; any;
	dataimage:boolean;
	constructor(private spinnerService: Ng4LoadingSpinnerService,private userService: UserService, private http: HttpClient, private router: Router, private JobService: JobService) {
		this.cropperSettings = new CropperSettings();
		this.cropperSettings.width = 70;
		this.cropperSettings.height = 70;
		this.cropperSettings.croppedWidth = 70;
		this.cropperSettings.croppedHeight = 70;
		this.cropperSettings.canvasWidth = 200;
		this.cropperSettings.canvasHeight = 200;
		this.cropperSettings.rounded=true;
		this.cropperSettings.touchRadius=39;
		this.cropperSettings.preserveSize =true;
		this.cropperSettings.compressRatio =8.3;
		this.cropperSettings.noFileInput=true;
		this.cropperSettings.preserveSize =true;
        this.cropperSettings.cropperClass="croppimag";
		this.data = {};
		 }

	ngOnInit() {

		
		if(!localStorage.getItem("country"))
		{
			this.router.navigate(['/signup-step2']);
		}

		if(localStorage.getItem("images") !=null)
		{
			this.dataimage =true;
			this.imagename = localStorage.getItem("images");
		}
		this.spinnerService.hide();
		this.imageSrc ="assets/images/profileimg.jpg";
		this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

			var filname = JSON.parse(response);
			this.docfile = filname.filename;

			localStorage.setItem("docfile", this.docfile);
  
			this.spinnerService.hide();
		}

		this.JobService.getServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

		if(localStorage.getItem("docfile") !=null)
		{
			this.docfile = localStorage.getItem("docfile");
		}
	}

		fileChangeEvent(event: any): void {
			this.imageChangedEvent = event;
		}
		imageCropped(event: any) {

			this.data.image = event.base64;
		}
		imageLoaded() {
			// show cropper
		}
		loadImageFailed() {
			// show message
		}

	onSubmit(form: NgForm) {
		this.spinnerService.show();
		localStorage.setItem("serviceType", form.value.serviceType);
		localStorage.setItem("serviceTypeId", form.value.serviceTypeId);
		localStorage.setItem("references", form.value.references);
		if(this.data.image)
		{
				localStorage.setItem("images",this.data.image);
				
		}
		setTimeout(() => {
			//localStorage.clear();
			this.router.navigate(['/signup-step4']);
		}, 4000);
		

		/* this.userService.postUser(form.value).subscribe(
			res => { 
				this.showSucessMessage = true; 
				setTimeout(() => this.showSucessMessage = false, 4000);
				this.resetForm(form);
			},
			err => { 
				if(err.status == 422){
					this.serverErrorMessages = err.error.join('<br/ >');
				}
				else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';	
			}		
		); */
	}

	// Image preview

	readURL(event): void {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;

			reader.readAsDataURL(file);
		}
	}

	//get docfile name 

	fileEvent(fileInput) {
		let file = fileInput.target.files[0];
		this.fileName = file.name;
		this.docfile = "";
	}


	fileChangeListener($event) {
		var image:any = new Image();
		var file:File = $event.target.files[0];
		var myReader:FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent:any) {
			image.src = loadEvent.target.result;
			that.cropper.setImage(image);

	 
		};
	 
		myReader.readAsDataURL(file);
	}


}