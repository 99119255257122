import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

import { MessagesService } from '../shared/messages.service';
import { useAnimation } from '@angular/animations';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MessageComponent implements OnInit {
  showFiller = false;
  chats = new Array();
  messages: any;
  chatId: String;
  newChatId: String;
  userId: String;
  userRole: String;
  messageText: String;
  typing: Boolean = false;
  jobProviderId: String;
  jobSeekerId: String;
  adminId: String;
  timeout: any;
  typerName: String;
  typerImage: String;
  receiverId1: String;
  receiverId2: String;
  offileUser: String;
  userStatus: String = 'Offline';
  firstName: String = '';
  lastName: String = '';
  image: String = '';
  jobId: String = '';
  jobTitle: String = '';
  defaultimage: String = "assets/images/noimg.png";
  searchText;

  constructor(private router: Router, private route: ActivatedRoute, private messagesService: MessagesService, private toastr: ToastrManager) {
    this.messagesService.newMessageReceived()
      .subscribe((data) => {
        if ((data.senderId == localStorage.getItem("id") || data.receiverId1 == localStorage.getItem("id") || data.receiverId2 == localStorage.getItem("id")) && this.chatId == data.chatRecordId)
          this.messages.messagerecords.push(data)
      });

    this.messagesService.typingStatus()
      .subscribe((data) => {
        if (this.chatId == data.chatId && this.userId != data.typerId) {
          if (data.status == true) {
            this.typing = true
          } else {
            this.typing = false
          }
          // console.log(data)
          this.typerName = data.typerName;
          this.typerImage = data.typerImage;
          this.newChatId = data.chatId;
          this.receiverId1 = data.receiverId1;
          this.receiverId2 = data.receiverId2;
        }
      });

    this.messagesService.userLeft()
      .subscribe((data) => {
        let receiverId1;
        if (this.userId == this.jobProviderId) {
          receiverId1 = this.jobSeekerId;
        } else if (this.userId == this.jobSeekerId) {
          receiverId1 = this.jobProviderId;
        }
        if (this.chatId && (data.userId == receiverId1)) {
          this.offileUser = data.userId;
          this.userStatus = 'Offline';
        }
      });

    this.messagesService.userJoin()
      .subscribe((data) => {
        let receiverId1;
        if (this.userId == this.jobProviderId) {
          receiverId1 = this.jobSeekerId;
        } else if (this.userId == this.jobSeekerId) {
          receiverId1 = this.jobProviderId;
        }
        if (this.chatId && (data.userId == receiverId1)) {
          this.offileUser = data.userId;
          this.userStatus = 'Online';
        }
      });

    this.messagesService.sendUserStatus()
      .subscribe((data) => {
        console.log(data)
        let receiverId1;
        if (this.userId == this.jobProviderId) {
          receiverId1 = this.jobSeekerId;
        } else if (this.userId == this.jobSeekerId) {
          receiverId1 = this.jobProviderId;
        }
        if (this.chatId && (data.userId == receiverId1)) {
          this.offileUser = data.userId;
          this.userStatus = data.status;
        }
      });
  }

  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['/login']);
    }
    this.userRole = localStorage.getItem("role");
    this.userId = localStorage.getItem("id")
    this.messagesService.joinRoom({ senderId: this.userId })
    if (localStorage.getItem("jobId") && localStorage.getItem("receiverId")) {
      let data = {
        userId: this.userId,
        jobId: localStorage.getItem("jobId"),
        receiverId: localStorage.getItem("receiverId"),
        role: localStorage.getItem('role')
      }
      localStorage.removeItem("jobId")
      localStorage.removeItem("receiverId")
      this.messagesService.getAllChats(data).subscribe(
        (result) => {
          // this.chats = result['data1'];
          for (let i = 0; i < result['data1'].length; i++) {
            this.chats.push({
              _id: result['data1'][i]._id,
              modified_at: result['data1'][i].modified_at,
              chatStatus: result['data1'][i].chatStatus,
              jobtitle: result['data1'][i]['jobs'][0].jobtitle,
              jobs: result['data1'][i]['jobs'],
              user_firstName_lastname: result['data1'][i]['users'][0].firstName + ' ' + result['data1'][i]['users'][0].lastName,
              users: result['data1'][i]['users'],
              messagerecords: result['data1'][i]['messagerecords']
            })
          }
          this.chats.map((ele) => {
            if (result['chatId'] == ele._id) {
              this.getChats(result['chatId'], ele.users[0].firstName, ele.users[0].lastName, ele.users[0].image, ele.jobs[0].jobtitle, ele.jobs[0]._id);
            }
          })
        },
        (error) => {
          this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
        }
      )
    } else {
      let data = {
        userId: this.userId,
        role: localStorage.getItem('role')
      }
      this.messagesService.getAllChats(data).subscribe(
        (result) => {
          // this.chats = result['data1'];
          // console.log(this.chats)
          // let localChats = new Array();
          for (let i = 0; i < result['data1'].length; i++) {
            this.chats.push({
              _id: result['data1'][i]._id,
              modified_at: result['data1'][i].modified_at,
              chatStatus: result['data1'][i].chatStatus,
              jobtitle: result['data1'][i]['jobs'][0].jobtitle,
              jobs: result['data1'][i]['jobs'],
              user_firstName_lastname: result['data1'][i]['users'][0].firstName + ' ' + result['data1'][i]['users'][0].lastName,
              users: result['data1'][i]['users'],
              messagerecords: result['data1'][i]['messagerecords']
            })
          }
          if (localStorage.getItem('chatId')) {
            this.chats.map((ele) => {
              if (localStorage.getItem('chatId') == ele._id) {
                this.getChats(localStorage.getItem('chatId'), ele.users[0].firstName, ele.users[0].lastName, ele.users[0].image, ele.jobs[0].jobtitle, ele.jobs[0]._id);
              }
            })
            localStorage.removeItem('chatId');
          }
        },
        (error) => {
          this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
        }
      )
    }
  }

  getChats(chatId, firstName, lastName, image, jobTitle, jobid = null) {
    if (jobid)
      this.jobId = jobid
    this.firstName = firstName
    this.lastName = lastName
    this.image = image
    this.jobTitle = jobTitle
    this.messageText = "";
    this.chatId = chatId;
    var data = {
      chatId: chatId
    }
    this.messagesService.messagesHistory(data).subscribe(
      (result) => {
        this.messages = result[0];
        this.jobProviderId = result[0].serviceProvider[0]._id;
        this.jobSeekerId = result[0].serviceSeeker[0]._id;
        this.adminId = result[0].admin[0]._id;
        let receiverId1;
        if (this.userId == result[0].serviceProvider[0]._id) {
          receiverId1 = result[0].serviceSeeker[0]._id;
        } else if (this.userId == result[0].serviceSeeker[0]._id) {
          receiverId1 = result[0].serviceProvider[0]._id;
        }
        this.messagesService.checkUserStatus({ userId: receiverId1, senderId: this.userId });
      },
      (error) => {
        this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
      }
    )
  }

  sendMessage() {
    let trimMessage = this.messageText.trim();
    let newMessage = trimMessage.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, "");
    if (newMessage != '') {
      if (this.chatId) {
        var data = {
          chatRecordId: this.chatId,
          senderId: localStorage.getItem("id"),
          message: trimMessage,
          role: localStorage.getItem('role')
        }
        this.messagesService.sendMessage(data);
        this.isTyping("stopTyping");
        this.messageText = "";
      }
    } else {
      this.messageText = "";
    }
  }

  isTyping(istyping = 'startTyping') {
    let receiverId1, receiverId2;
    if (this.userId == this.jobProviderId) {
      receiverId1 = this.jobSeekerId;
      receiverId2 = this.adminId;
    } else if (this.userId == this.jobSeekerId) {
      receiverId1 = this.jobProviderId;
      receiverId2 = this.adminId;
    } else {
      receiverId1 = this.jobSeekerId;
      receiverId2 = this.jobProviderId;
    }
    let data = {
      typerId: this.userId,
      typerName: localStorage.getItem("fullName"),
      receiverId1: receiverId1,
      receiverId2: receiverId2,
      chatId: this.chatId,
      status: true
    }

    if (istyping == "stopTyping") {
      this.timeoutFunction(data)
    } else {
      this.messagesService.isTyping(data);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.timeoutFunction(data)
      }, 2000)
    }
  }

  timeoutFunction(data) {
    let newData = data;
    newData.status = false;
    this.messagesService.isTyping(newData);
  }
}
