import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { UserService } from '../../shared/user.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

	showSucessMessage: boolean;
	serverErrorMessages: string;
	constructor(private userService: UserService) { }
	ngOnInit() {

	}

	onSubmit(form: NgForm) {

		console.log(form.value);
		this.userService.postUser(form.value).subscribe(
			res => {
				this.showSucessMessage = true;
				setTimeout(() => this.showSucessMessage = false, 4000);
				this.resetForm(form);
			},
			err => {
				if (err.status == 422) {
					this.serverErrorMessages = err.error.join('<br/ >');
				}
				else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);
	}

	resetForm(form: NgForm) {
		this.userService.selectedUser = {
			firstName: localStorage.getItem("firstName") ? localStorage.getItem("firstName") : '',
			lastName: localStorage.getItem("lastName") ? localStorage.getItem("lastName") : '',
			email: localStorage.getItem("email") ? localStorage.getItem("email") : '',
			phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : '',
			country: localStorage.getItem("country") ? localStorage.getItem("country") : '',
			street: localStorage.getItem("street") ? localStorage.getItem("street") : '',
			houseNo: localStorage.getItem("houseNo") ? localStorage.getItem("houseNo") : '',
			area: localStorage.getItem("area") ? localStorage.getItem("area") : '',
			serviceType: localStorage.getItem("serviceType") ? localStorage.getItem("serviceType") : '',
			references: localStorage.getItem("references") ? localStorage.getItem("references") : '',
			serviceTypeId: localStorage.getItem("serviceTypeId") ? localStorage.getItem("serviceTypeId") : '',
			selectBank: localStorage.getItem("selectBank") ? localStorage.getItem("selectBank") : '',
			accountNo: localStorage.getItem("accountNo") ? localStorage.getItem("accountNo") : '',
			password: localStorage.getItem("password") ? localStorage.getItem("password") : '',
			conpassword: localStorage.getItem("conpassword") ? localStorage.getItem("conpassword") : '',
			imagename: 'http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000/uploads/' + localStorage.getItem("image"),
			baseurl: '',
			docid: '',
			image: '',
			userid: ''
		};
		form.resetForm();
		this.serverErrorMessages = '';

	}

}
