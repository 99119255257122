import { Component, OnInit } from '@angular/core';

import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Router } from '@angular/router';


@Component({
	selector: 'app-sign-up-step4',
	templateUrl: './sign-up-step4.component.html',
	styleUrls: ['./sign-up-step4.component.css']
})
export class SignUpStep4Component implements OnInit {

	showSucessMessage: boolean;
	serverErrorMessages: string;
	allstepdata: any;
	banks:any;

	accounnumber:Number;

	constructor(private userService: UserService, private http: HttpClient, private router: Router) { }

	ngOnInit() {
	
		if(!localStorage.getItem("serviceType"))
		{
			this.router.navigate(['/signup-step3']);
		}

		this.userService.getBanks().subscribe(
			(res: any) => {
				this.banks = res.data;
			}
		);

	}
	onSubmit(form: NgForm) {
		//step1 

		var firstName = localStorage.getItem("firstName");

		var lastName = localStorage.getItem("lastName");

		var email = localStorage.getItem("email");

		var password = localStorage.getItem("password");

		var phone = localStorage.getItem("phone");

		var role = localStorage.getItem("role");

		//step2

		var country = localStorage.getItem("country");
		var street = localStorage.getItem("street");
		var houseNo = localStorage.getItem("houseNo");
		var area = localStorage.getItem("area");

		// step 3

		var serviceType = localStorage.getItem("serviceType");
		var serviceTypeId = localStorage.getItem("serviceTypeId");
		var references = localStorage.getItem("references");
		var image = localStorage.getItem("image");
		var docfile = localStorage.getItem("docfile");
	    var images = 	localStorage.getItem("images");

	
		// step 4

		var selectBank = form.value.selectBank;
		var accountNo = form.value.accountNo;

		localStorage.setItem("selectBank", form.value.selectBank);

		localStorage.setItem("accountNo", form.value.accountNo);
		this.allstepdata = {"images":images, "selectBank": selectBank, "accountNo": accountNo, "firstName": firstName, "lastName": lastName, "email": email, "password": password, "phone": phone, "role": role, "country": country, "street": street, "houseNo": houseNo, "area": area, "serviceType": serviceType, "serviceTypeId": serviceTypeId, "references": references, "image": image, "docfile": docfile }
		this.userService.postUser(this.allstepdata).subscribe(
			res => {

				this.showSucessMessage = true;
				setTimeout(() => this.showSucessMessage = false, 4000);
				this.resetForm(form);

				setTimeout(() => {
				//	localStorage.clear();
					this.router.navigate(['/login']);
				}, 3000);

			},
			err => {

				alert(this.allstepdata);
				if (err.status == 422) {
					this.serverErrorMessages = err.error.join('<br/ >');
				}
				else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);
	}

	resetForm(form: NgForm) {
		// this.userService.selectedUser = {
		// 	fullName: '',
		// 	email: '',
		// 	password: ''

		// };
		form.resetForm();
		this.serverErrorMessages = '';
	}
	mychange(val) {
		const self = this;
		let chIbn = val.toString().split('-').join('');
		if (chIbn.length > 0) {
		  chIbn = chIbn.match(new RegExp('.{1,4}', 'g')).join('-');
		}
		this.userService.selectedUser.accountNo = chIbn;
	  }
}
