import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { JobService } from '../../shared/job.service';
import { environment } from '../../../environments/environment';

export interface DialogData {
  rating: number;
  comment: string;
}

@Component({
  selector: 'app-mycontracts',
  templateUrl: './mycontracts.component.html',
  styleUrls: ['./mycontracts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MycontractsComponent implements OnInit {

  contractInfo: any;
  serviceSeekar: any;
  serviceProvider: any;
  rateReview: any;
  yourRating: any;
  anotherRating: any;
  activeJobsCount: number = 0;
  completedJobsCount: number = 0;
  status: string;
  baseurl: string;
  readMoreLess: Number = 0;
  rating: number = 1;
  comment: string;
  yourRatingDone: number = 0;
  anotherRatingDone: number = 0;
  userId: String;
  messageText: String;
  receiverId: String;
  jobId: String;
  jobseeakrrate: Number;
  jobproviderrate: Number;
  services: any;
  invoicedata:any;
  filename:any;

  constructor(private jobService: JobService, private router: Router, private route: ActivatedRoute, private toastr: ToastrManager, public dialog: MatDialog) { }

  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['/login']);
    }
    this.jobId = this.route.snapshot.paramMap.get('id')
    this.userId = localStorage.getItem("id")
    this.baseurl = environment.pdfBaseUrl;
    this.getContract();
  }

  changeJobapplystatus(event,userid,jobappplyid)
  {
  
    var data = {"userid": userid,"jobId":this.jobId,"clientmsg":event.target.value,"jobapplyid":jobappplyid}
    if(confirm("Are you sure send this status to client")) {
      this.jobService.setjobclientslatus(data).subscribe(
        (res: any) => {
          this.toastr.successToastr('Message send to jobseekar Successfully ');
        },
        err => {
          //alert('Error' + err);
        }
      );

    
    }


  }

  getContract() {
    this.activeJobsCount = 0;
    var data = {
      jobId: this.route.snapshot.paramMap.get('id'),
      userId: localStorage.getItem("id")
    }
    this.jobService.getServices().subscribe(
      (res: any) => {
        this.services = res.data;
      },
      err => {
      //  alert('Error' + err);
      }
    );


    this.jobService.getServiceSeekarContract(data).subscribe(
      (res: any) => {
        if (res.jobDetails && res.jobDetails[0]) {
          this.status = res.jobDetails[0].status;
          this.contractInfo = res.jobDetails[0];
          this.serviceSeekar = res.serviceSeekar && res.serviceSeekar[0];
          res.serviceSeekar && res.serviceSeekar[0].jobsDetails.forEach(element => {
            if (element.status == 'active' || element.status == 'hired')
              ++this.activeJobsCount;
          });
          this.serviceProvider = res.serviceProvider && res.serviceProvider[0];
          res.serviceProvider && res.serviceProvider[0].jobsDetails.forEach(element => {
            if (element.status == 'completed')
              ++this.completedJobsCount;
          });
          this.rateReview = res.rateReview;
          if (res.jobproviderrates) {
            //Job provider rates
            var jprates = res.jobproviderrates;
            var jpotal = Object.keys(res.jobproviderrates).length;
            var startnumber = 0;
            for (let starrating of jprates) {
              startnumber += Number(starrating.rating);
            }
            this.jobproviderrate = Math.round(startnumber / jpotal);

            //job seekar rates
            var jsrates = res.jobseekarrates;

            var jsotal = Object.keys(jsrates).length;
            var startnumber = 0;
            for (let starrating of jsrates) {

              startnumber += Number(starrating.rating);
            }

            this.jobseeakrrate = Math.round(startnumber / jsotal);
          }

          res.rateReview && res.rateReview.forEach(element => {
            if (element.userFromId == localStorage.getItem("id")) {
              this.yourRating = element;
              this.yourRatingDone = 1;
            } else {
              this.anotherRating = element;
              this.anotherRatingDone = 1;
            }
          });
        } else {
          this.toastr.warningToastr('No contract found.', 'Warning');
          setTimeout(() => {
            this.router.navigate(['/myjobs']);
          }, 5000);
        }
      },
      (error: any) => {
        this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
      }
    )
  }

  hireServiceProvider(jobId, jobApplyId) {
    var data = {
      jobId: jobId,
      jobApplyId: jobApplyId,
      userId: localStorage.getItem("id")
    }
    if(confirm("Are you sure, you want to hire this jobseeker")) {
    this.jobService.hireServiceProvider(data).subscribe(
      (res: any) => {
        this.toastr.successToastr(res['status'], 'Success');
        this.getContract();
      },
      (error: any) => {
        this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
      }
    )
    };
  }

  getReadLess(type: number) {
    this.readMoreLess = type;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { rating: this.rating, comment: this.comment }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != 'close') {
        var data = {
          rating: result.rating,
          comment: result.comment,
          jobId: this.route.snapshot.paramMap.get('id'),
          userFromId: localStorage.getItem("id"),
          userToId: this.serviceProvider._id
        }
        this.jobService.ratingReview(data).subscribe(
          (res: any) => {
            this.getContract();
            this.toastr.successToastr(res['status'], 'Success');
          },
          (err: any) => {
            this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
          }
        )
      }
    });
  }

  startChat(receiverId) {
    localStorage.setItem("jobId", this.route.snapshot.paramMap.get('id'))
    localStorage.setItem("receiverId", receiverId)
    this.router.navigate(['/message']);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {

  public ownerForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.ownerForm = new FormGroup({
    
      comment: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(1000)])
    });
  }

  onNoClick(type: any): void {
    this.dialogRef.close(type);
  }

  closeDialog = (data: any) => {
    this.dialogRef.close(data);
  }

  setRating(rate: number) {
    this.data.rating = rate;
  }

  

}
