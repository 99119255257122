import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/authentication.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from "jwt-decode";
import { SocialUser } from 'ng-social-login-module';
import {
	AuthService,
	FacebookLoginProvider,
	GoogleLoginProvider,
	LinkedinLoginProvider
} from 'ng-social-login-module';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
	providers: [CookieService]
})
export class LoginComponent implements OnInit {
	showSucessMessage: boolean;
	serverErrorMessages: string;
	remmber: boolean;
	firstName: string;
	socialUser: SocialUser;
	lastName: string;
	registerAccount: string;
	constructor(private authService: AuthService, private cookieService: CookieService, private AuthenticationService: AuthenticationService, private router: Router) { }
	ngOnInit() {
		if (localStorage.getItem("token") != null) {
			if (localStorage.getItem('role') == "admin") {
				this.router.navigate(['/admin/message']);
			} else if (localStorage.getItem('role') == "jobSeekar") {
				this.router.navigate(['/findwork']);
			} else {
				this.router.navigate(['/jobpost']);
			}
		}

		if (this.cookieService.get('remember')) {
			var gettokenvalue = jwt_decode(this.cookieService.get('token'));
			var password = gettokenvalue.password;
			var email = gettokenvalue.email;
			// console.log(gettokenvalue);
			var cookiedata = { 'email': email, 'password': password };

			this.AuthenticationService.postUser(cookiedata).subscribe(
				(res: any) => {
					var token = res.token;
					var userid = res.data.id;
					var role = res.data.role;
					var fullName = res.data.firstName + " " + res.data.lastName;
					localStorage.setItem('token', token);
					localStorage.setItem('id', userid);
					localStorage.setItem('fullName', fullName);
					localStorage.setItem('role', role);
					
					if (role == "admin") {
						this.router.navigate(['/admin']);
					} else if (role == "jobSeekar") {
						this.router.navigate(['/findwork']);
					} else {
						this.router.navigate(['/jobpost']);
					}
				},
				err => {
					//alert("eirouiwyriw");
					if (err.status == 422) {
						this.serverErrorMessages = err.error.join('<br/ >');
					}
				}
			);
		}
	}
	signInWithGoogle(): void {
		this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}
	signInWithFB(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}
	signInWithLinkedIN(): void {
		this.authService.signIn(LinkedinLoginProvider.PROVIDER_ID);
	}
	toggleEditable(e) {
		if (e.target.checked) {
			this.remmber = true;
		}
		else {
			this.remmber = false;

		}
	}
	splitNameInFirstLast(name: string) {
		const nameSplit = name.split(RegExp(' ', 'g'));
		if (nameSplit.length === 3) {
			this.firstName = nameSplit[0] + ' ' + nameSplit[1];
			this.lastName = nameSplit[2];
		} else if (nameSplit.length === 2) {
			this.firstName = nameSplit[0];
			this.lastName = nameSplit[1];
		} else if (nameSplit.length === 4) {
			this.firstName = nameSplit[0] + ' ' + nameSplit[1];
			this.lastName = nameSplit[2] + ' ' + nameSplit[3];
		} else {
			this.firstName = nameSplit[0];
		}
	}
	setSocialValuesToUser(socialUser: SocialUser) {
		// Optional if you want to separate first name and last name
		this.splitNameInFirstLast(socialUser.name);
		// Set values to registerAccount object
		//    this.registerAccount.firstName = this.firstName;
		//    this.registerAccount.lastName = this.lastName;
		//    this.registerAccount.email = socialUser.email;
		//    // Optional , you can skip if u want default uuid
		//    this.registerAccount.id = socialUser.id;
		//    //Custom fields added to default user for managing social logins
		//    //Name of the social provider
		//    this.registerAccount.socialProvider = socialUser.provider;
		//    //Set true if user login from social
		//    this.registerAccount.isSocialLogin = 'true';
		//    //For disabling fields after we fetch from social login
		//    this.disableFields();
	}
	signUpWithGoogle(): void {
		this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
			.then(user => {
				this.socialUser = user;
				var keyval = Object.keys(user)[2]
				var value = user.provider;
				alert(value);
				console.log();
				this.setSocialValuesToUser(this.socialUser)
			}
			);
	}
	signUpfacebook(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
			.then(user => {
				this.socialUser = user;
				var keyval = Object.keys(user)[2]
				var email = user.email;
				var name = user.name;
				alert(email);
				alert(name);
				console.log(user);
				this.setSocialValuesToUser(this.socialUser)
			}
			);
	}
	onSubmit(form: NgForm) {
		this.AuthenticationService.postUser(form.value).subscribe(
			(res: any) => {
		if(res.status == false)
		{
			this.serverErrorMessages = res.message;
		}
		else
		{
				var token = res.data.token;
				var userid = res.data._id;
				var role = res.data.role;
				var fullName = res.data.firstName + " " + res.data.lastName;
				localStorage.setItem('token', token);
				localStorage.setItem('id', userid);
				localStorage.setItem('fullName', fullName);
				localStorage.setItem('role', role);

				//adding below street, houseNo, area
				localStorage.setItem("street",res.data.street)
				localStorage.setItem("houseNo",res.data.houseNo)
				localStorage.setItem("area",res.data.area)
				if (this.remmber) {
					this.cookieService.set('token', token);
					this.cookieService.set('remember', 'remmber');
				}

				if (localStorage.getItem('role') == "admin") {
					this.router.navigate(['/admin/message']);
				} else if (localStorage.getItem('role') == "jobSeekar") {
					this.router.navigate(['/findwork']);
				} else if (localStorage.getItem('role') == "jobProvider") {
					this.router.navigate(['/find-service-provider']);
				}
			}
		},
			err => {
			
					this.serverErrorMessages = err.message;
			
			}
		);
	}

	resetForm(form: NgForm) {
		this.AuthenticationService.selectedUser = {
			email: '',
			password: ''
		};
		form.resetForm();
		this.serverErrorMessages = '';
	}
}