import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  serverErrorMessages:boolean;
	invoicelist: any;
	baseurl:any;
	totalinvoices:any;
	searchstring:any;
	pagedata:any;
	serviceproviders:any;
	searchtext:any;
	recordnotfound:any;
	constructor(private adminService: AdminService,private router: Router) { }

	ngOnInit() {


		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

		this.baseurl=environment.pdfBaseUrl;
		var pagedata = {'pageNo':0,'pageSize':10};
		this.adminService.getInvoicelist(pagedata).subscribe(
			(res: any) => {
				if (res.data != null) {
					this.invoicelist = res.data.invoiceslist;
					this.totalinvoices= res.data.total;
				} else {
					this.serverErrorMessages = true;
				}
			});
	}

	changePage(event:any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
	
		let data = {
		  pageNo: pageNo,
		  pageSize: pageSize
		}
	
		this.adminService.getInvoicelist(data).subscribe(
			(res: any) => {
				if (res.data != null) {
					this.invoicelist = res.data.invoiceslist;
					this.totalinvoices= res.data.total;
				} else {
					this.serverErrorMessages = true;
				}
			});
	  }

}
