/// <reference types="@types/googlemaps" />
import { Component, OnInit, Pipe, PipeTransform  } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../../environments/environment';
import { JobService } from '../../shared/job.service';
import { DomSanitizer} from '@angular/platform-browser';


@Component({
	selector: 'app-viewjob',
	templateUrl: './viewjob.component.html',
	styleUrls: ['./viewjob.component.css']
})
@Pipe({ name: 'safe' })
export class ViewjobComponent implements OnInit {
	title: string = 'My first AGM project';
	lat: number = 51.678418;
	lng: number = 7.809007;
	services:any;
	private sub: any;
	viewdata: string;
	checkjobapplystatus: boolean;
	checkuserrole: string;
	iframeURLs:any;
	iframeurl:any;
	getStartrating:any;
	useridjobprovider:any;
	numberofstar:Number;
	role:any;
	total:Number;
	baseurl:any;
	applyNowData: any = { name: '', subject: '', priceMethod: "", paymentMethod: 'hourly', hourlyRate:'' , estimatedHours: '', materialsCosts: '', estimatedPrice: '0', fixedPrice: '', message: '', jobId: '', providerUserId: '' };
	constructor(private sanitizer: DomSanitizer,private route: ActivatedRoute, private router: Router, private JobService: JobService, public toastr: ToastrManager) {
	  
	 }
	 ConvertToInt(val){
		return parseInt(val);
	  }
	ngOnInit() {
	this.baseurl = environment.imgBaseUrl;
		this.role=localStorage.getItem("role");
				// Getjob detail
		this.checkuserrole = localStorage.getItem('role');
		this.applyNowData.name = localStorage.getItem('fullName');
		let jobid = this.route.snapshot.paramMap.get('id');

		this.JobService.getServices().subscribe(
			(res: any) => {
			this.services = res.data;
				
			},
		
			err => {
				alert('Error' + err);
			}
		);
		this.JobService.viewjob(jobid).subscribe(
			(res: any) => {
					this.viewdata = res.data[0];
					this.applyNowData.jobId = this.viewdata['_id'];
					this.applyNowData.providerUserId = this.viewdata['userid'];
					this.iframeurl =false;
					this.iframeURLs="https://www.google.com/maps?q="+res.data[0].country+"&output=embed";
					this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURLs);
					this.useridjobprovider = res.data[0].userid;
					localStorage.setItem('jobproviderid',this.useridjobprovider);
					
					//alert(this.iframeURL);
			
			}, err => {
				alert("error" + err);
			}
		);

		// Check if user already Apply for the job
		this.JobService.checkJobApply(jobid).subscribe(
			(res: any) => {
				if (res.docs == null) {
					this.checkjobapplystatus = false;
				} else {
					this.checkjobapplystatus = true;
				}
			},
			err => {
				alert("error" + err);
			}
		);

		this.JobService.startRating().subscribe(
			(res: any) => {
			
					this.getStartrating = res.rating;
				  var counttotalrating = Object.keys(res.rating).length;
				  
				   var startnumber = 0;
					for (let starrating of this.getStartrating) {

						startnumber +=Number(starrating.rating);
					}
					
					this.numberofstar = startnumber/counttotalrating;
				},
			err => {
				alert("error" + err);
			}
		);
	}
	toHTML(input) : any {
		return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
	}
	// Job Apply
	jobApply() {
		if (this.applyNowData.priceMethod == 0) {
			this.applyNowData.paymentMethod = 'hourly';
		} else {
			this.applyNowData.paymentMethod = 'fixed';
		}

		this.applyNowData.estimatedPrice = ((this.ConvertToInt(this.applyNowData.hourlyRate) * this.ConvertToInt(this.applyNowData.estimatedHours))+this.ConvertToInt(this.applyNowData.materialsCosts));
		this.JobService.jobApply(this.applyNowData).subscribe(
			(res: any) => {
				this.toastr.successToastr(res['message'], 'Success');
				this.checkjobapplystatus = true;
			}, err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
			}
		);
	}
	numberOnly(event): boolean {
		
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		  return false;
		}
		return true;
	
	  }
}