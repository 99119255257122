import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { UserService } from '../../../shared/user.service';
import { environment } from '../../../../environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AdminService } from '../../../shared/admin.service';
@Component({
  selector: 'app-addhowitwork',
  templateUrl: './addhowitwork.component.html',
  styleUrls: ['./addhowitwork.component.css']
})
export class AddhowitworkComponent implements OnInit {
  constructor(private spinnerService: Ng4LoadingSpinnerService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
  pagedata:any;
  imageSrc: any;
	public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl+'/addhowitsection', itemAlias: 'bannerimage'
	});
  ngOnInit() {
    var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }
      this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		  this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			console.log('ImageUpload:uploaded:', item, status, response);
					localStorage.setItem("howitwork", response);
		}
  }

  onSubmit(form: NgForm) {
    this.spinnerService.show();
    setTimeout(() => {
    this.adminservice.addhowitwork(form.value).subscribe(
    (res:any) => {
      this.toastr.successToastr('Add Successfullly');
      this.spinnerService.hide();
      this.router.navigate(['/admin/managehowitworks']);
          },
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}, 2000);
}


public imagePath;
imgURL: any;
public message: string;

preview(files) {
  if (files.length === 0)
    return;

  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }

  var reader = new FileReader();
  this.imagePath = files;
  reader.readAsDataURL(files[0]); 
  reader.onload = (_event) => { 
    
    this.imgURL=reader.result;
  }
}
}
