import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { UserService  } from '../../shared/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
 
 responsedata:string; 

  constructor(private router:Router,private userService:UserService) {

    }

  ngOnInit() {

//alert(localStorage.getItem("token"));

if (localStorage.getItem("token") == null)
 {

  this.router.navigate(['/login']);

 }


var useremail = localStorage.getItem("id");


 this.userService.gettUser(useremail).subscribe(

			(res:any) => {

			
		
		},
			err => { 

			alert("erro");
				
			}		
		);

  }

}
