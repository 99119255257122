import { Component, OnInit } from '@angular/core';
import { JobService } from '../../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../../shared/admin.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-howitworks',
  templateUrl: './howitworks.component.html',
  styleUrls: ['./howitworks.component.css']
})
export class HowitworksComponent implements OnInit {

  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
howitworks:any;
baseurl:any;
  ngOnInit() {
this.baseurl = environment.imgBaseUrl;
		var role =  localStorage.getItem('role');
		if(role =="jobSeekar")
		{
		this.router.navigate(['/findwork']);
		} 
	  if(role == "jobProvider")
		{
		  this.router.navigate(['/jobpost']);
		}

    this.adminservice.gethowitworks().subscribe(
			(res:any) => {
				this.howitworks = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
	
			}
		);
  }

  deletehowitsworkBank(hid)
	{
		if(confirm("Are you sure to delete "+name)) {


		this.adminservice.deletehowitswork(hid).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Delete Successfullly');
				this.router.navigate(['/admin/managehowitworks']);
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

		
	}


else

{
	return false;
}

}

}
