import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private socket = io('http://ec2-52-47-182-0.eu-west-3.compute.amazonaws.com:3000');

  constructor(private http: HttpClient) { }

  joinRoom(data) {
    this.socket.emit('join', data);
  }

  sendMessage(data) {
    this.socket.emit('message', data);
  }

  newMessageReceived() {
    let observable = new Observable<{ chatRecordId: String, created_at: Date, senderId: String, receiverId1: String, receiverId2: String, message: String, status: String }>(observer => {
      this.socket.on('newMessage', (data) => {
        if (window.location.pathname == '/message' || window.location.pathname == '/admin/message') {
          observer.next(data);
        } else if (localStorage.getItem("id") == data.receiverId1 || localStorage.getItem("id") == data.receiverId2) {
          data.userId = localStorage.getItem("id");
          this.socket.emit('notification', data);
        }
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  messagesHistory(data) {
    return this.http.post(environment.apiBaseUrl + '/getMessageHistory', data);
  }

  getAllChats(data) {
    return this.http.post(environment.apiBaseUrl + '/getAllChats', data);
  }

  checkChat(data) {
    return this.http.post(environment.apiBaseUrl + '/checkChat', data);
  }

  isTyping(data) {
    this.socket.emit('isTyping', data);
  }

  typingStatus() {
    let observable = new Observable<{ typerId: String, receiverId1: String, receiverId2: String, chatId: String, status: Boolean, typerName: String, typerImage: String }>(observer => {
      this.socket.on('typingStatus', (data) => {
        observer.next(data);
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  userLeft() {
    let observable = new Observable<{ userId: String }>(observer => {
      this.socket.on('userleft', (data) => {
        observer.next(data);
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  userJoin() {
    let observable = new Observable<{ userId: String }>(observer => {
      this.socket.on('userjoin', (data) => {
        observer.next(data);
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  checkUserStatus(data) {
    this.socket.emit('checkUserStatus', data);
  }

  sendUserStatus() {
    let observable = new Observable<{ userId: String, status: String }>(observer => {
      this.socket.on('sendUserStatus', (data) => {
        observer.next(data);
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  disconnectUser() {
    this.socket.emit('logout');
  }

  setNotification(data) {
    this.socket.emit('notification', data);
  }

  getNotifications() {
    return this.http.get(environment.apiBaseUrl + '/messageNotification/' + localStorage.getItem('id'));
  }

  getNewNotification() {
    let observable = new Observable<{ userId: String, status: String }>(observer => {
      this.socket.on('getNewNotification', (data) => {
        observer.next(data);
      });
      return () => { this.socket.disconnect(); }
    });
    return observable;
  }

  setNotificationAsRead(notificationId) {
    return this.http.get(environment.apiBaseUrl + '/readNotification/' + notificationId);
  }
}
