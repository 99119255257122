import { Component, OnInit } from '@angular/core';

import {DomSanitizer} from '@angular/platform-browser';
import { JobService } from '../../shared/job.service'
import { LoginComponent } from 'src/app/user/login/login.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-allcategories',
  templateUrl: './allcategories.component.html',
  styleUrls: ['./allcategories.component.css']
})
export class AllcategoriesComponent implements OnInit {

  constructor( private JobService: JobService) { }
  pagedata:any;

 service:any;
 baseurl:any;
 login:any;

  toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
}
  ngOnInit() {

    if (localStorage.getItem("token") == null) {
    
    this.login = localStorage.getItem("token");
    }

    this.baseurl =environment.imgBaseUrl;
    this.JobService.getServices().subscribe(
			(res: any) => {
				this.service = res.data;
			}
		);

  }

  
}
