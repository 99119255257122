import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edittestimonial',
  templateUrl: './edittestimonial.component.html',
  styleUrls: ['./edittestimonial.component.css']
})
export class EdittestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    
  }

}
