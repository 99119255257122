import { Component, OnInit } from '@angular/core';
import { JobService } from '../../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../../shared/admin.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {

  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
  testimonial:any;
  baseurl:any;
  ngOnInit() {
	var role =  localStorage.getItem('role');
	if(role =="jobSeekar")
	{
	this.router.navigate(['/findwork']);
	} 
  if(role == "jobProvider")
	{
	  this.router.navigate(['/jobpost']);
	}
this.baseurl =environment.imgBaseUrl;
    this.adminservice.gettestimonial().subscribe(
			(res:any) => {
				this.testimonial = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
	
			}
		);
  }

  deletetestimonial(tid)
	{
		if(confirm("Are you sure to delete "+name)) {


		this.adminservice.deletetestimonial(tid).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Delete Successfullly');
				this.router.navigate(['/admin/testimonial']);
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

		
	}


else

{
	return false;
}

}

}
