import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sign-up-type',
  templateUrl: './sign-up-type.component.html',
  styleUrls: ['./sign-up-type.component.css']
})
export class SignUpTypeComponent implements OnInit {

  constructor(private router:Router) { 
   
  }
  ngOnInit() {

    if (localStorage.getItem("token") != null) {
		//	this.router.navigate(['/dashboard']);
    }
    
  }

}
