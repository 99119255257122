import { Component, OnInit } from '@angular/core';
import { JobService } from '../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bank-management',
  templateUrl: './bank-management.component.html',
  styleUrls: ['./bank-management.component.css']
})
export class BankManagementComponent implements OnInit {

  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
banks:any;
  ngOnInit() {

		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

    this.adminservice.getBanks().subscribe(
			(res:any) => {
				this.banks = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
	
			}
		);
  }

  deleteBank(bankid)
	{
		if(confirm("Are you sure to delete "+name)) {


		this.adminservice.deleteBank(bankid).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Delete Successfullly');
					 
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

		this.router.navigate(['/admin/banks-management']);
	}


else

{
	return false;
}

}

}
