import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AdminService } from '../../shared/admin.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.css']
})
export class EditBankComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,private route: ActivatedRoute, private router: Router, private adminService: AdminService, public toastr: ToastrManager) { }
  bankdata:string;
  ngOnInit() {


		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }


    let bankid = this.route.snapshot.paramMap.get('id');
    this.adminService.bankdetail(bankid).subscribe(
			(res: any) => {
				if (res.data) {
					this.bankdata = res.data[0];
					
				}
			}, err => {
				alert("error" + err);
			}
		);


  }

  onSubmit(form: NgForm) {
    this.spinnerService.show();
    let bankid = this.route.snapshot.paramMap.get('id');
    form.value.bankid = bankid;
    this.adminService.updatebank(form.value).subscribe(
    (res:any) => {
      this.spinnerService.hide();
      this.router.navigate(['/admin/banks-management']);
  
          this.toastr.successToastr('bank update  Successfullly');
                }
    ,
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}


}
