import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service'
import { MessagesService } from '../shared/messages.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  providers: [CookieService]
})
export class LogoutComponent implements OnInit {

  constructor(private cookieService: CookieService, private router: Router, private messagesService: MessagesService) { }

  ngOnInit() {
    this.messagesService.disconnectUser();
    localStorage.clear();
    this.cookieService.delete('remember');
    this.cookieService.delete('token');
    this.router.navigate(['/login']);
  }
}
