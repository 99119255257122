import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobService } from '../../shared/job.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css']
})
export class JobDetailComponent implements OnInit {

  jobid: any;
  jobdata: any;
  contractInfo: any;
  serviceSeekar: any;
  serviceProvider: any;
  rateReview: any;
  yourRating: any;
  anotherRating: any;
  activeJobsCount: number = 0;
  completedJobsCount: number = 0;
  status: string;
  baseurl: string;
  readMoreLess: Number = 0;
  rating: number = 1;
  comment: string;
  yourRatingDone: number = 0;
  anotherRatingDone: number = 0;
  jobproviderrate: Number;
  jobseeakrrate: Number;
  services:any;
  totalproviderjob:any;
  totalcompletejob :any;
  jstotal:any;
  invoicedata:any;
  filename:any;
  baseurlimg:any;
  constructor(private JobService:JobService,public toastr: ToastrManager, private adminservice: AdminService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

 this.baseurlimg = environment.imgBaseUrl;
		var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

    this.baseurl = environment.pdfBaseUrl;
    this.JobService.getServices().subscribe(
			(res: any) => {
				this.services = res.data;
							},
			err => {
				alert('Error' + err);
			}
		);

    this.jobid = this.route.snapshot.paramMap.get('id');
    
    this.JobService.getInvoice('',this.jobid).subscribe(
			(res: any) => {
				this.invoicedata = res.data;
				if(this.invoicedata[0])
				{
					this.filename = this.invoicedata[0].pdfFile;
				}
						},
			err => {
				alert('Error' + err);
			}
		);
      

    // this.adminservice.jobDetail(this.jobid).subscribe(
    // 	(res: any) => {

    // 			this.jobdata = res.job[0];

    // 	}, err => {
    // 		alert("error" + err);
    // 	}
    // );

    //Get job detail

    

    this.adminservice.jobDetail(this.jobid).subscribe(
      (res: any) => {

        if (res.jobDetails && res.jobDetails[0]) {
          for (let singleJob of res.jobDetails[0].jobsdes) {
            if (singleJob.status == 'hired' || singleJob.status == 'completed')
              this.status = res.jobDetails[0].status;
          }
          
          this.contractInfo = res.jobDetails[0];
          this.totalproviderjob = res.totalproviderjob;
          this.totalcompletejob = res.totalprovider;
          this.serviceProvider = res.serviceProvider && res.serviceProvider[0];
          this.jstotal = res.jstotal;
        if(res.serviceSeekar[0])
          {
                   this.serviceSeekar = res.serviceSeekar[0].userdetail;
          }
         
          if (res.jobproviderrates) {
            
            //Job provider rates
            var jprates = res.jobproviderrates;
            var jpotal = Object.keys(res.jobproviderrates).length;
            var startnumber = 0;
            for (let starrating of jprates) {
              startnumber += Number(starrating.rating);
            }
            this.jobproviderrate = Math.round(startnumber / jpotal);
     
          }
          if (res.jobseekarrates)
          {
          
            //job seekar rates
            var jsrates = res.jobseekarrates;
            var jsotal = Object.keys(jsrates).length;
            var startnumber = 0;
            for (let starrating of jsrates) {
              startnumber += Number(starrating.rating);
            }
            this.jobseeakrrate = Math.round(startnumber / jsotal);
            
          }
          res.serviceSeekar && res.serviceSeekar[0].jobsDetails.forEach(element => {
            if (element.status == 'active' || element.status == 'hired')
              ++this.activeJobsCount;
          });

          res.serviceProvider && res.serviceProvider[0].jobsDetails.forEach(element => {
            if (element.status == 'completed')
              ++this.completedJobsCount;
          });
          this.rateReview = res.rateReview;

          res.rateReview && res.rateReview.forEach(element => {
            if (element.userFromId == localStorage.getItem("id")) {
              this.yourRating = element;
              this.yourRatingDone = 1;
            } else {
              this.anotherRating = element;
              this.anotherRatingDone = 1;
            }
          });
        } else {
          this.toastr.warningToastr('No contract found.', 'Warning');
          setTimeout(() => {
            this.router.navigate(['/myjobs']);
          }, 5000);
        }
      },
      (error: any) => {
        this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
      }
    );

    }

  changejobstatus(status) {
    var jobdata = { "status": status, "jobid": this.jobid };

    this.adminservice.updateJobstatus(jobdata).subscribe(
      (res: any) => {

        this.toastr.successToastr(res['status'], 'Update Success!');

        this.router.navigate(['/admin/jobs']);

      }, err => {
        this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
      }
    );
  }




}
