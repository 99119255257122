import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AdminService {
	constructor(private http: HttpClient) { }

	getAllUsers(pagedata) {

		return this.http.post(environment.apiBaseUrl + '/admin/users', pagedata);
	}

	getUserDetail(userId) {

		userId = { 'userid': userId };
		return this.http.post(environment.apiBaseUrl + '/getuserbyid', userId);
	}

	jobDetail(jobid) {
		var jobdata = { "jobid": jobid };
		return this.http.post(environment.apiBaseUrl + '/admin/getcontract', jobdata);

	}

	updateJobstatus(jobdata) {
		return this.http.post(environment.apiBaseUrl + '/admin/updatejobstatus', jobdata);
	}

	addcountry(formdata) {
		var userid = localStorage.getItem('id');
		formdata.id = userid;

		return this.http.post(environment.apiBaseUrl + '/admin/addcountry', formdata);

	}

	addServices(formdata) {
		var userid = localStorage.getItem('id');
		formdata.id = userid;
		return this.http.post(environment.apiBaseUrl + '/admin/service', formdata);

	}

	updatecountry(countrydata) {

		return this.http.post(environment.apiBaseUrl + '/admin/updatecountry', countrydata);
	}

	//Delete country

	deleteCountry(countryid) {
		var countrydata = { "countryid": countryid };
		return this.http.post(environment.apiBaseUrl + '/admin/deletecountry', countrydata);

	}

	deleteService(serviceid) {
		var servicedata = { "serviceid": serviceid };
		return this.http.post(environment.apiBaseUrl + '/admin/deleteservice', servicedata);


	}

	getServices() {
	
		return this.http.get(environment.apiBaseUrl + '/admin/service');

	}
	//get country detail

	countrydetail(countryid) {
		var countrydata = { "countryid": countryid };
		return this.http.post(environment.apiBaseUrl + '/admin/getcountrydetail', countrydata);

	}

	// Add bank

	addbank(formdata) {
		var userid = localStorage.getItem('id');
		formdata.id = userid;
		return this.http.post(environment.apiBaseUrl + '/admin/addbank', formdata);

	}


	//get countries

	getCountries() {
		return this.http.get(environment.apiBaseUrl + '/admin/country');
	}

	// Get banks

	getBanks() {
		return this.http.get(environment.apiBaseUrl + '/admin/getbanks');

	}
	// bankdetail

	bankdetail(id) {
		var bankdata = { "bankid": id };
		return this.http.post(environment.apiBaseUrl + '/admin/getbankdetail', bankdata);
	}
	// Update bank detail
	updatebank(bankdata) {

		return this.http.post(environment.apiBaseUrl + '/admin/updatebank', bankdata);
	}

	// delete Bank

	deleteBank(bankid) {
		var bankdata = { "bankid": bankid };
		return this.http.post(environment.apiBaseUrl + '/admin/deletebank', bankdata);
	}

	//get service detail

	servicedetail(serviceid) {
		var servicedata = { "serviceid": serviceid };
		return this.http.post(environment.apiBaseUrl + '/admin/getservicedetail', servicedata);

	}

	// update service

	updateService(servicedata) {
		return this.http.post(environment.apiBaseUrl + '/admin/updateService', servicedata);
	}

	jobsearchpagination(data) {
		var userId = localStorage.getItem('id');
		var role = localStorage.getItem('role');
		var jobsearchdata = { "userId": userId, "role": role, "searchtext": data.searchtext, "pageSize": data.pageSize, "pageNo": data.pageNo };

		return this.http.post(environment.apiBaseUrl + '/myjobsearch', jobsearchdata);

	}

	// change job status

	changeadminstatus(id, status) {
		var jobsearchdata = { "jobid": id, "status": status };
		return this.http.post(environment.apiBaseUrl + '/changeJobadminstatus', jobsearchdata);

	}


	//usersearchbyName

	usersearchbyName(data) {

		return this.http.post(environment.apiBaseUrl + '/usersearchbyName', data);

	}
	//Get job resports
	getjobReports() {
		return this.http.post(environment.apiBaseUrl + '/getjobReports', {});
	}

	// add static pages

	addPage(pagedata) {

		return this.http.post(environment.apiBaseUrl + '/admin/addPage', pagedata);
	}
	//Get static pages
	getPages() {
		return this.http.get(environment.apiBaseUrl + '/getPages');
	}
	// get page detail
	pagedetail(id) {
		var pagedata = { "pageid": id };
		return this.http.post(environment.apiBaseUrl + '/admin/getPagesdetail', pagedata);
	}

	//Edit page 
	updatePagesdetail(data) {
		return this.http.post(environment.apiBaseUrl + '/admin/updatePagesdetail', data);
	}

	//Delete page

	deletePage(pageid) {
		var data = { pageid: pageid }
		return this.http.post(environment.apiBaseUrl + '/admin/deletePage', data);
	}

	// Get all jobs
	getAlljobs(data) {
		return this.http.post(environment.apiBaseUrl + '/getAlljobsadmin', data);
	}
	// Get all jobs

	Updatehomepage(data) {
		var bannerimage = localStorage.getItem("homepageimage");
		data.bannerimage = bannerimage;
		return this.http.post(environment.apiBaseUrl + '/updatehomepagecontent', data);
	}

	gethomepagecontent() {
		return this.http.post(environment.apiBaseUrl + '/admin/gethomepagecontent', {});
	}
	//Add how it work section
	addhowitwork(data) {
		var bannerimage = localStorage.getItem("howitwork");
		data.bannerimage = bannerimage;
		return this.http.post(environment.apiBaseUrl + '/addhowitsection', data);
	}

	//Get How it work section
	gethowitworks() {
		return this.http.post(environment.apiBaseUrl + '/admin/gethowitwork', {});
	}
	//get single how it work section
	gethowitworksbyid(id) {

		return this.http.post(environment.apiBaseUrl + '/admin/getsinglehowitwork', { "sectionid": id });
	}

	//update how it section
	updatehowitworksbyid(data) {
		data.iconimage = localStorage.getItem("howitwork");
		return this.http.post(environment.apiBaseUrl + '/admin/updatehowitsection', data);
	}


	//Add how it work section
	addtestimonial(data) {
		var clientimage = localStorage.getItem("clientimage");
		data.clientimage = clientimage;
		return this.http.post(environment.apiBaseUrl + '/admin/addTestimonial', data);
	}

	//Get testimonial
	gettestimonial() {
		return this.http.post(environment.apiBaseUrl + '/admin/gettestimonial', {});
	}


	//Get single testimonial

	getsingleTestimonial(id) {

		return this.http.post(environment.apiBaseUrl + '/admin/getsingletestimonial', { "sectionid": id });
	}
	//update how it section
	updatetestimonail(data) {

		data.clientimage = localStorage.getItem("clientimage");
		return this.http.post(environment.apiBaseUrl + '/admin/updateTestimonial', data);
	}

	deletehowitswork(hid)
	{
		var data = { hid: hid }
		return this.http.post(environment.apiBaseUrl + '/admin/deletehowitsection', data);
	}


	//Delete testimonial

	deletetestimonial(tid)
	{
		var data = { tid: tid }
		return this.http.post(environment.apiBaseUrl + '/admin/deletetestimonial', data);
	}
	 // get contact list
   
	 getContactlist(pagedata)
	 {
		return this.http.post(environment.apiBaseUrl + '/admin/getContactList', pagedata);
	 }
	 //get invoice list
	 getInvoicelist(pagedata)
	 {
		return this.http.post(environment.apiBaseUrl + '/admin/getinvoices', pagedata);
	 }

   //get user reports
   getuserReports() {

		return this.http.post(environment.apiBaseUrl + '/getuserReports', {});
	}
	
   //get user reports
   getmonhtlyjobReports() {

	return this.http.post(environment.apiBaseUrl + '/getmonhtlyjobReports', {});
}


getRatesreviews(pagedata)
{
	return this.http.post(environment.apiBaseUrl + '/admin/getRatesreviews',pagedata);
}

changeReviewadminstatus(id,status)
{
	var data = {"reviewid":id,"status":status};
	return this.http.post(environment.apiBaseUrl + '/changeReviewadminstatus',data);

}

getmonhtlyUsers()
{
	return this.http.post(environment.apiBaseUrl + '/getmonhtlyUsers',{});
	
}




   }

  