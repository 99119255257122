import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { JobService } from '../../shared/job.service';

@Component({
  selector: 'app-find-service-provider',
  templateUrl: './find-service-provider.component.html',
  styleUrls: ['./find-service-provider.component.css']
})
export class FindServiceProviderComponent implements OnInit {

  role: any;
  totalusers: any;
  services: any;
  categoryrecordmsg: boolean;
  searchtext: string;
  pagedata: any;
  searchstring: any;
  categoryset: any;
  recordnotfound: boolean;
  jobs: any = ['active', 'closed', 'completed', 'expired', 'hired'];
  jobsProvider: any = ['active', 'hired', 'completed'];
  JobType: string;
  jobCount: number;
  countries: any
  serviceproviders: any;
  country: any;
  optionsChecked = [];
  constructor(private jobService: JobService, private router: Router) { }
  ngOnInit() {

this.country="";
    localStorage.setItem("searchtext", "");
    localStorage.setItem("country", "");
    this.searchtext = "";
    // Check whether the login or not
    this.role = localStorage.getItem('role');
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['/login']);
    }

    // gt country

    this.jobService.getCountries().subscribe(
      (res: any) => {
        this.countries = res.data;
      }
    );
    // get service
    this.jobService.getServices().subscribe(
      (res: any) => {
        this.services = res.data;
        for (let service of this.services) {
          this.optionsChecked.push(service.serviceName)
        }
        localStorage.setItem('category', JSON.stringify(this.optionsChecked));
      }
    );

    // get service providers
    var pagedata = { 'pageNo': 0, 'pageSize': 10};
    this.jobService.getServiceproviders(pagedata).subscribe(
      (res: any) => {
        this.serviceproviders = res.data.users;
        this.totalusers = res.data.total;
      },
      (error: any) => {
        alert("Error: " + error);
      }
    );
    
  }

  // Service Seekar change page functionality

  changePage(event: any) {
    let pageNo = event.pageIndex;
    let pageSize = event.pageSize;

    let data = {
      pageNo: pageNo,
      pageSize: pageSize
    }
    this.jobService.getServiceproviders(data).subscribe(
      (res: any) => {

        this.serviceproviders = res.data.users;
        this.totalusers = res.data.total;


      });
  }
  // Service Provider change page functionality
  changePageServiceProvider(event: any, statusType: any) {
    let pageNo = event.pageIndex;
    let pageSize = event.pageSize;
    let data = {
      userId: localStorage.getItem('id'),
      jobStatus: statusType,
      pageNo: pageNo,
      pageSize: pageSize
    }
    // console.log(data)
  }

  // search service provider
  spsearch() {
    this.searchstring = "search";
    localStorage.setItem("searchtext", this.searchtext);
    localStorage.setItem("country", this.country);
    var category = localStorage.getItem('category');

    if (!this.country) {
      alert("Please select country");
    }
    else {
      this.pagedata = { pageNo: 0, pageSize:10, 'searchtext': this.searchtext, 'country': this.country, "category": JSON.parse(category) };
      this.jobService.spsearch(this.pagedata).subscribe(
        (res: any) => {
          this.serviceproviders = res.data.users;
          this.totalusers = res.data.total;
          if (this.totalusers < 1) {
            this.recordnotfound = true;
          }
          else {
  
            this.recordnotfound = false;
          }
        },
        err => {
          alert("Error" + err);
        }
      );

    }
  }
  //
  getuserByCategory(category, event) {
    this.services[category] = event.target.checked;
    if (event.target.checked) {
      this.optionsChecked.push(category);
    }
    else {
      if (this.optionsChecked.includes(category)) {
        this.optionsChecked.splice(this.optionsChecked.indexOf(category), 1);
      }
    }
    this.searchstring = "search";
    localStorage.setItem('category', JSON.stringify(this.optionsChecked));
    this.pagedata = { pageNo: 0, pageSize: 10, category: JSON.parse(JSON.stringify(this.optionsChecked)), "country": this.country };
    this.jobService.getuserByCategory(this.pagedata).subscribe(
      (res: any) => {
        if (res.data) {
          this.serviceproviders = res.data.users;
          this.totalusers = res.data.total;
        }
        if (this.totalusers < 1) {
          this.recordnotfound = true;
        }
        else {

          this.recordnotfound = false;
        }
      },
      err => {
        alert("Error" + err);
      }
    );
  }
  // change page after serach

  changePageAftersearch(event: any) {
    let pageNo = event.pageIndex;
    let pageSize = event.pageSize;
    var searchtext = localStorage.getItem("searchtext");
    var category = localStorage.getItem('category');
    var country = localStorage.getItem("country");
    let data = {
      pageNo: pageNo,
      pageSize: pageSize,
      "searchtext": searchtext,
      "country" : country,
      "category":category
    }

    this.jobService.spsearch(data).subscribe(
      (res: any) => {
        this.serviceproviders = res.data.users;
        this.totalusers = res.data.total;

      },
      (error: any) => {
        alert("Error: " + error);
      }
    );
  }
}