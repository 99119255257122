import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../shared/admin.service';
import { ToastrManager } from 'ng6-toastr-notifications';

import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-showratereview',
  templateUrl: './showratereview.component.html',
  styleUrls: ['./showratereview.component.css']
})
export class ShowratereviewComponent implements OnInit {
	constructor(private toastr:ToastrManager,private adminservice:AdminService,private router: Router) { }
	getallreviews: any;
	totaljobs: any;
	storevalue: string;
	recordnotfound: boolean;
	pagedata: any;
	searchstring: any;
	optionsChecked = [];
	emptyarray = [];
	ngOnInit() {
	
		var role =  localStorage.getItem('role');
		if(role =="jobSeekar")
		{
		this.router.navigate(['/findwork']);
		} 
	  if(role == "jobProvider")
		{
		  this.router.navigate(['/jobpost']);
		}
		var pagedata = { pageNo: 0, pageSize: 10 };
		this.adminservice.getRatesreviews(pagedata).subscribe(
			(res: any) => {
				if (res) {
					this.getallreviews = res.data;
					this.totaljobs = res.totalrecord;

				}
			},
			err => {
				alert("Error" + err);
			}
		);

	

	}
	changePage(event: any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
		let data = {
			pageNo: pageNo,
			pageSize: pageSize
		}
		this.adminservice.getRatesreviews(data).subscribe(
			(res: any) => {
				if (res) {
					this.getallreviews = res.data;
					this.totaljobs = res.totalrecord;

				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}

	// change admin status
	changeReviewadminstatus(id,event)
	{

		if(event.target.checked)
		{
    var status= "yes";
		}
		else{

			var status ="no";
		}
		this.adminservice.changeReviewadminstatus(id,status).subscribe(
			(res: any) => {
				this.toastr.successToastr(res.message, 'Success');
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	
	}


}
