import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { JobService } from '../../shared/job.service';
import * as $ from 'jquery';
import { environment } from '../../../environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
@Component({
  selector: 'app-myjobs',
  templateUrl: './myjobs.component.html',
  styleUrls: ['./myjobs.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MyjobsComponent implements OnInit {
  role: any;
  date:any;
  myActiveJobs: any;
  myClosedJobs: any;
  myCompletedJobs: any;
  myExpiredJobs: any;
  myHiredJobs: any;
  totalActiveCount: number = 0;
  totalClosedCount: number = 0;
  totalCompletedCount: number = 0;
  totalExpiredCount: number = 0;
  totalHiredCount: number = 0;
  getalljobs: string;
  totaljobs: any;
  responsedata: string;
  viewdata: string;
  storevalue: string;
  services: any;
  categoryrecordmsg: boolean;
  searchtext: string;
  pagedata: any;
  searchstring: any;
  useridjobapply:any;
  categoryset: any;
  recordnotfound:boolean;
  baseurl:any;
  jobs: any = ['active', 'closed', 'completed', 'expired', 'hired'];
  jobsProvider: any = ['active', 'hired', 'completed'];
  JobType: string;
  jobCount: number;
  options: DatepickerOptions = {
		minYear: 2019,
		maxYear: 2030,
		displayFormat: 'MMM D YYYY',
		barTitleFormat: 'MMMM D YYYY',
    dayNamesFormat: 'dd',
 
		firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
		minDate: new Date(Date.now()), // Minimal selectable date
		barTitleIfEmpty: 'Click to select a date',
		placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
		addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
		addStyle: {}, // Optional, value to pass to [ngStyle] on the input field

		// ID to assign to the input field. Defaults to datepicker-<counter>
		useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
	  };
  constructor(private tostr:ToastrManager,private jobService: JobService, private router: Router) { }
  today:any ;
  ngOnInit() {
this.baseurl = environment.imgBaseUrl;
    const now = new Date;
    this.today =now.toISOString();
  this.useridjobapply = localStorage.getItem('id');
      this.date="";
  localStorage.setItem("date","");
    localStorage.setItem("searchtext", "");
    this.searchtext = "";
    // Check whether the login or not
    this.role = localStorage.getItem('role');
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['/login']);
    }
    if (this.role == 'jobProvider') { //  For Service Seekar Jobs
      for (let i = 0; i < 4; i++) {
        let data = {
          userId: localStorage.getItem('id'),
          jobStatus: this.jobs[i],
          pageNo: 0,
          pageSize: 10
        }
        this.jobService.getjobs(data).subscribe(
          (res: any) => {
          
            this.myActiveJobs = res.data.joblist;
            this.totaljobs = res.data.total;
            if (this.totaljobs < 1) {
              this.recordnotfound = true;
            }
          },
          (error: any) => {
          //  alert("Error: " + error);
          }
        );
      }
    } else if (this.role == 'jobSeekar') {  //  For Service Provider Jobs
      for (let i = 0; i < 3; i++) {
        let data = {
          userId: localStorage.getItem('id'),
          jobStatus: this.jobsProvider[i],
          pageNo: 0,
          pageSize: 5
        }
        this.jobService.getServiceProviderJobs(data).subscribe(
          (res: any) => {
            this.myActiveJobs = res.data.joblist;
            this.totaljobs = res.data.total
            if (this.totaljobs < 1) {
              this.recordnotfound = true;
            }
          },
          (error: any) => {
          //  alert("Error: " + error);
          }
        );
      }
    }
  }

  changedate(id,event)
  {
    if(event.target.id)
    {
    localStorage.setItem("date",event.target.id);
    }
    else
    {
      if(confirm("Are you sure to change expire date ")) {

    var getid = localStorage.getItem("date");
     var date = $("#"+getid).val();

     this.date = {"jobid":id,changedate:date};
     this.jobService.changejobstatus(this.date).subscribe(
      (res: any) => {
      	this.tostr.successToastr('Expire date update Successfully');
      },
      (error: any) => {
        alert("Error: " + error);
      }
    );
    }

    }

  }
  // Service Seekar change page functionality
  changePage(event: any, statusType: any) {
    let pageNo = event.pageIndex;
    let pageSize = event.pageSize;
    let data = {
      userId: localStorage.getItem('id'),
      jobStatus: statusType,
      pageNo: pageNo,
      pageSize: pageSize
    }
    this.jobService.getjobs(data).subscribe(
      (res: any) => {
        this.myActiveJobs = res.data.joblist;
        this.totaljobs = res.data.total;
      },
      (error: any) => {
        alert("Error: " + error);
      }
    );
  }
  // Service Provider change page functionality
  changePageServiceProvider(event: any, statusType: any) {
    let pageNo = event.pageIndex;
    let pageSize = event.pageSize;
    let data = {
      userId: localStorage.getItem('id'),
      jobStatus: statusType,
      pageNo: pageNo,
      pageSize: pageSize
    }
    // console.log(data)
    this.jobService.getServiceProviderJobs(data).subscribe(
      (res: any) => {
        this.myActiveJobs = res.data.joblist;
        this.totaljobs = res.data.total;
      },
      (error: any) => {
        alert("Error: " + error);
      }
    );
  }
  myjobsearch() {
    this.searchstring = "search";
    localStorage.setItem("searchtext", this.searchtext);
    this.pagedata = { pageNo: 0, pageSize: 10, 'searchtext': this.searchtext,"role":this.role};
    this.jobService.myjobsearch(this.pagedata).subscribe(
      (res: any) => {
        if (res.data) {
          this.myActiveJobs = res.data.joblist;
          this.totaljobs = res.data.total;
                 }
        if (this.totaljobs < 1) {
          this.recordnotfound = true;
        }
        else {
          this.recordnotfound = false;
        }
      },
      err => {
        alert("Error" + err);
      }
    );
  }

  // change page after serach

  changePageAftersearch(event: any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
		var searchtext = localStorage.getItem("searchtext");
			let data = {
			pageNo: pageNo,
			pageSize: pageSize,
			"searchtext": searchtext,
		}

		this.jobService.myjobsearchpagination(data).subscribe(
			(res: any) => {
				this.myActiveJobs = res.data.joblist;
				this.totaljobs = res.data.total;
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	}


}