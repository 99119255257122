import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/admin.service';
import { environment } from '../../../environments/environment';
@Component({
	selector: 'app-admin-users',
	templateUrl: './admin-users.component.html',
	styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent implements OnInit {
	allUsers: any;
	serverErrorMessages: boolean;
	baseurl:any;
	totalusers:any;
	searchstring:any;
	pagedata:any;
	serviceproviders:any;
	searchtext:any;
	recordnotfound:any;
	constructor(private adminService: AdminService) { }

	ngOnInit() {

		this.searchtext="";
		this.baseurl=environment.imgBaseUrl;
		var pagedata = {'pageNo':0,'pageSize':10};
		this.adminService.getAllUsers(pagedata).subscribe(
			(res: any) => {
				if (res.users != null) {
					this.allUsers = res.users;

					this.totalusers= res.total;
				} else {
					this.serverErrorMessages = true;
				}
			});
	}

	changePage(event:any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
	
		let data = {
		  pageNo: pageNo,
		  pageSize: pageSize
		}
	
		this.adminService.getAllUsers(data).subscribe(
			(res: any) => {
				if (res.users != null) {
					this.allUsers = res.users;
					this.totalusers= res.total;
				} else {
					this.serverErrorMessages = true;
				}
			});
	  }


	  spsearch() {
		this.searchstring = "search";
		if (!this.searchtext) {
		  alert("Please Enter the in searchbox");
		}
		else {
		  this.pagedata = { pageNo: 0, pageSize: 10, 'searchtext': this.searchtext};
		  this.adminService.usersearchbyName(this.pagedata).subscribe(
			(res: any) => {
			  this.allUsers = res.data.users;
			  this.totalusers = res.data.total;
			  if (this.totalusers < 1) {
				this.recordnotfound = true;
			  }
			  else {
	  				this.recordnotfound = false;
			  }
			},
			err => {
			  alert("Error" + err);
			}
		  );
	
		}
	  }

}
