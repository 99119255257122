import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  pagedata:any;
  constructor(private userService: UserService) { }
  ngOnInit() {
    this.userService.getpage('5ca1ce5171b3ed2b5437ddca').subscribe(
			(res:any) => {
				this.pagedata = res.data[0];

			},
			err => {
      }
		);
 
    // this.adminService.pagedetail(ursl).subscribe(
		// 	(res: any) => {
		// 		if (res.data) {
		// 			this.pagedata = res.data[0];
					
		// 		}
		// 	}, err => {
		// 		alert("error" + err);
		// 	}
		// );
  }

}
