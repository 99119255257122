import { Directive } from '@angular/core';

@Directive({
  selector: '[appNhFormatInput]'
})
export class NhFormatInputDirective {

 

}
