import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserService } from '../shared/user.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
	protected aFormGroup: FormGroup;
  constructor(private formBuilder: FormBuilder,private toastr:ToastrManager,private spinnerService: Ng4LoadingSpinnerService,private userService: UserService) { }

  ngOnInit() {
		this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
  }
	onSubmit(form: NgForm) {
		this.spinnerService.show();
		this.userService.contactUs(form.value).subscribe(
			(res: any) => {
					this.toastr.successToastr('Form submit successfully');
			
						});


	}

}
