import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { JobService } from '../../shared/job.service';
@Component({
	selector: 'app-getjobs',
	templateUrl: './getjobs.component.html',
	styleUrls: ['./getjobs.component.css']
})

export class GetjobsComponent implements OnInit {

	responsedata: string;
	viewdata: string;
	getalljobs: string;
	storevalue: string;
	services: any;
	categoryrecordmsg: boolean;
	searchtext: string;
	totaljobs: any;
	pagedata: any;
	searchstring: any;
	optionsChecked = [];
	emptyarray = [];
	categoryset: any;
	countries :any;
	onmsg:boolean;
	constructor(private JobService: JobService, private router: Router, private route: ActivatedRoute) { }

	ngOnInit() {
		localStorage.setItem("category", "");
		localStorage.setItem("searchtext", "");
		localStorage.setItem("sortorder", "");
		this.searchtext = "";
		if (localStorage.getItem("token") == null) {
			this.router.navigate(['/login']);
		}
		var id = localStorage.getItem("id");
		this.storevalue = localStorage.getItem('role');
		this.JobService.getjobs(id).subscribe(
			(res: any) => {
				if (res.docs) {
					this.responsedata = res.docs;

				}
			},
			err => {
				alert("Error" + err);
			}
		);


		//Get all job

		this.pagedata = { pageNo: 0, pageSize: 10,userid:id };

		this.JobService.getAlljobs(this.pagedata).subscribe(
			(res: any) => {
				if (res) {
					this.getalljobs = res.data.joblist;
					this.totaljobs = res.data.total;
					this.countries = res.data.country;
					if (this.totaljobs < 1) {
						this.onmsg = true;
						}
						else
						{
							this.onmsg =false;
						}
				}
			},
			err => {
				alert("Error" + err);
			}
		);
		// Get All Services
		this.JobService.getServices().subscribe(
			(res: any) => {
				this.services = res.data;
				for (let service of this.services) {
					this.optionsChecked.push(service._id)
				}
			},
			err => {
				//alert('Error' + err);
			}
		);

	}


	changePage(event: any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
		let data = {
			pageNo: pageNo,
			pageSize: pageSize
		}
		this.JobService.getAlljobs(data).subscribe(
			(res: any) => {
				this.getalljobs = res.data.joblist;
				this.totaljobs = res.data.total;
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	}


	changePageAftersearch(event: any) {
		let pageNo = event.pageIndex;
		let pageSize = event.pageSize;
		var category = localStorage.getItem("category");
		var searchtext = localStorage.getItem("searchtext");
		var sortorder = localStorage.getItem("sortorder");
		let data = {
			pageNo: pageNo,
			pageSize: pageSize,
			"searchtext": searchtext,
			"sortorder": sortorder,
			"category": category
		}

		this.JobService.jobsearchpagination(data).subscribe(
			(res: any) => {
				this.getalljobs = res.docs;
				this.totaljobs = res.total;
			
			},
			(error: any) => {
				alert("Error: " + error);
			}
		);
	}
	// Getjobs by category
	getJobByCategory(category, event) {
		this.services[category] = event.target.checked;
		if (event.target.checked) {
			this.optionsChecked.push(category);
		}
		else {
			if (this.optionsChecked.includes(category)) {
				this.optionsChecked.splice(this.optionsChecked.indexOf(category), 1);
			}
		}


		this.searchstring = "search";
		localStorage.setItem('category', JSON.stringify(this.optionsChecked));
		this.pagedata = { pageNo: 0, pageSize: 10, category: JSON.stringify(this.optionsChecked) };
		this.JobService.getJobByCategory(this.pagedata).subscribe(
			(res: any) => {
				if (res.data) {
					this.getalljobs = res.data.joblist;
					this.totaljobs = res.total;
					console.log(res.recordCount);
					this.categoryset = res.service;
				}


				if (this.totaljobs < 1) {
					this.categoryrecordmsg = true;
				}
				else {

					this.categoryrecordmsg = false;
				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}
	//jobsearch
	jobsearch() {

		this.searchstring = "search";
		localStorage.setItem("searchtext", this.searchtext);
		this.pagedata = { pageNo: 0, pageSize: 10, 'searchtext': this.searchtext };
		this.JobService.jobSearchByWord(this.pagedata).subscribe(

			(res: any) => {

				if (res.data) {
					this.getalljobs = res.data.joblist;
					this.totaljobs = res.total;
					this.categoryset = res.service;
				}
				if (this.totaljobs < 1) {
					this.categoryrecordmsg = true;
				}
				else {
					this.categoryrecordmsg = false;
				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}

	// Job sorting
	jobsearchsort(sortorder) {
		this.searchstring = "search";
		localStorage.setItem("sortorder", sortorder);
		this.pagedata = { pageNo: 0, pageSize: 10, 'sortorder': sortorder };
		this.JobService.jobSorting(this.pagedata).subscribe(
			(res: any) => {

				if (res.data) {
					this.getalljobs = res.data.joblist;
					this.totaljobs = res.total;
					this.categoryset = res.service;
				}
				if (this.totaljobs < 1) {
					this.categoryrecordmsg = true;
				}
				else {
					this.categoryrecordmsg = false;
				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}
	// view record
	viewrecord(job) {
		this.JobService.viewjob(job._id).subscribe(
			(res: any) => {
				if (res.data) {
					this.viewdata = res.data;
					this.router.navigate(['/viewjob', job._id])
				}
			},
			err => {
				alert("Error" + err);
			}
		);
	}
}