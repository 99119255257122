import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from './user.model';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	selectedUser: User = {
		firstName: localStorage.getItem("firstName") ? localStorage.getItem("firstName") : '',
		lastName: localStorage.getItem("lastName") ? localStorage.getItem("lastName") : '',
		email: localStorage.getItem("email") ? localStorage.getItem("email") : '',
		phone: localStorage.getItem("phone") ? localStorage.getItem("phone") : '',
		country: localStorage.getItem("country") ? localStorage.getItem("country") : '',
		street: localStorage.getItem("street") ? localStorage.getItem("street") : '',
		houseNo: localStorage.getItem("houseNo") ? localStorage.getItem("houseNo") : '',
		area: localStorage.getItem("area") ? localStorage.getItem("area") : '',
		serviceType: localStorage.getItem("serviceType") ? localStorage.getItem("serviceType") : '',
		references: localStorage.getItem("references") ? localStorage.getItem("references") : '',
		serviceTypeId: localStorage.getItem("serviceTypeId") ? localStorage.getItem("serviceTypeId") : '',
		selectBank: localStorage.getItem("selectBank") ? localStorage.getItem("selectBank") : '',
		accountNo: localStorage.getItem("accountNo") ? localStorage.getItem("accountNo") : '',
		password: localStorage.getItem("password") ? localStorage.getItem("password") : '',
		conpassword: localStorage.getItem("conpassword") ? localStorage.getItem("conpassword") : '',
		imagename: localStorage.getItem("image"),
		baseurl: environment.apiBaseUrl,
		docid: localStorage.getItem("docfile"),
		image: localStorage.getItem("image"),
		userid: localStorage.getItem("id")
	}

	constructor(private http: HttpClient) {
		var id = localStorage.getItem("id");

	}

	postUser(user: User) {
		return this.http.post(environment.apiBaseUrl + '/register', user);
	}

	// get user 
	gettUser(id) {

		var data = { userid: id };
		return this.http.post(environment.apiBaseUrl + '/getuserbyid', data);

	}
	// gettUserFullprofile
	gettUserFullprofile(id) {
		var data = { userid: id };
		return this.http.post(environment.apiBaseUrl + '/getuserfullprofile', data);

	}

	changePasswords(data) {
		data.userid = localStorage.getItem("id")

		return this.http.post(environment.apiBaseUrl + '/changePassword', data);
	}

	// update user
	updateUser(user: User) {

		user.docid = localStorage.getItem("docfile");
		user.image = localStorage.getItem("image");
		if (localStorage.getItem("userid") != null) {
			user.userid = localStorage.getItem("userid");
		}
		else {
			user.userid = localStorage.getItem("id");
		}
		return this.http.post(environment.apiBaseUrl + '/updateprofile', user);
	}

	// check email exist 
	checkemail(email) {
		var chkemail = { 'email': email }
		return this.http.post(environment.apiBaseUrl + '/checkemail', chkemail);
	}
	// check phone

	checkphone(phonedata) {
		return this.http.post(environment.apiBaseUrl + '/checkphone', phonedata);

	}
	//reset password
	resetpassword(user) {
		return this.http.post(environment.apiBaseUrl + '/resetpassword', user);
	}

	//verify user
	verifyUser(userid) {
		var user = { 'id': userid }
		return this.http.post(environment.apiBaseUrl + '/verifyUser', user);
	}
	//getcontractinfi
	getcontractdeatil(userid) {
		var user = { 'id': userid }
		return this.http.post(environment.apiBaseUrl + '/getcontractdeatil', user);
	}

	// get banks

	getBanks() {
		return this.http.get(environment.apiBaseUrl + '/banks', {});
	}

	// get static page

	getpage(pageid) {
		var pagedata = { 'pageid': pageid }

		return this.http.post(environment.apiBaseUrl + '/getfrontPagebyid', pagedata);
	}
	//Get client
	getClients() {
		return this.http.post(environment.apiBaseUrl + '/getReviewsfront',{});
	}
	//Contact us
	contactUs(data) {
		return this.http.post(environment.apiBaseUrl + '/contactus', data);
	}


}