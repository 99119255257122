import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../shared/user.service';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {

  constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }
  userprofile: any;
  jobdetails: any;
  rating: any;
  totalspend: any;
  jobseeakrrate: any;
  totalinvest: any;
  jobworked: any;

  ngOnInit() {

      if (localStorage.getItem("token") == null) {
        this.router.navigate(['/login']);
      }
    var userid = this.route.snapshot.paramMap.get('id2');
    this.userService.gettUserFullprofile(userid).subscribe(
      (res: any) => {
        this.userprofile = res.data.userdata;
        this.jobdetails = res.data.jobdetail;
        this.totalspend = res.data.totalspend;
        this.rating = res.data.rating;

        this.totalinvest = 0;
        if (this.totalspend) {
          for (let totals of this.totalspend) {

            this.totalinvest += Number(totals.estimatedPrice);
          }

        }

        if (this.rating) {
          var jsrates = this.rating
          var jsotal = Object.keys(jsrates).length;
          var startnumber = 0;
          for (let starrating of jsrates) {
            startnumber += Number(starrating.rating);
          }
          this.jobseeakrrate = Math.round(startnumber / jsotal);
        }

        this.jobworked = jsrates.jobsdetail;
      },
      err => {


      });

  }
}
