import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { UserService } from '../../shared/user.service';
import { JobService } from '../../shared/job.service';
import { environment } from '../../../environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
	selector: 'app-sign-up-step2',
	templateUrl: './sign-up-step2.component.html',
	styleUrls: ['./sign-up-step2.component.css'],
	providers: [UserService]
})
export class SignUpStep2Component implements OnInit {

	showSucessMessage: boolean;
	serverErrorMessages: string;
	gtelocalstorgae: string;
	firststep: string;
	allstepdata: any;
	userrole: string;
	countries: any;

	constructor(private spinnerService: Ng4LoadingSpinnerService,private userService: UserService, private jobService: JobService, private http: HttpClient, private router: Router) { }

	ngOnInit() {

		if(!localStorage.getItem("firstName"))
		{
			this.router.navigate(['/signuptype']);
		}
		this.spinnerService.hide();
		this.userrole = localStorage.getItem("role");
		this.jobService.getCountries().subscribe(
			(res:any) => {
				this.countries = res.data;
				console.log("this.countries",this.countries)
			},
			err => {
				if (err.status == 422) {
					this.serverErrorMessages = err.error.join('<br/ >');
				} else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);
	}

	onSubmit(form: NgForm) {
		this.spinnerService.show();
		localStorage.setItem("country", form.value.country);
		localStorage.setItem("street", form.value.street);
		localStorage.setItem("houseNo", form.value.houseNo);
		localStorage.setItem("area", form.value.area);
		let userrole = localStorage.getItem("role");

		if (userrole == "jobProvider") {
			var firstName = localStorage.getItem("firstName");
			var lastName = localStorage.getItem("lastName");
			var email = localStorage.getItem("email");
			var password = localStorage.getItem("password");
			var phone = localStorage.getItem("phone");
			var role = localStorage.getItem("role");
			//step2
			var country = localStorage.getItem("country");
			var street = localStorage.getItem("street");
			var houseNo = localStorage.getItem("houseNo");
			var area = localStorage.getItem("area");
			this.allstepdata = { "firstName": firstName, "lastName": lastName, "email": email, "password": password, "phone": phone, "role": role, "country": country, "street": street, "houseNo": houseNo, "area": area }

			this.userService.postUser(this.allstepdata).subscribe(
				res => {
					this.spinnerService.hide();
					this.showSucessMessage = true;
					setTimeout(() => this.showSucessMessage = false, 4000);
					this.resetForm(form);
					localStorage.clear();
					setTimeout(() => {
						localStorage.clear();
						this.router.navigate(['/login']);
					}, 3000);
				
				},
				err => {
					if (err.status == 422) {
						this.serverErrorMessages = err.error.join('<br/ >');
					}
					else
						this.serverErrorMessages = 'Something went wrong. please contact admin.';
				}
			);
		} else {
			this.firststep = "step2";
			this.router.navigate(['/signup-step3']);
		}
	}

	resetForm(form: NgForm) {
		// this.userService.selectedUser = {
		// 	fullName: '',
		// 	email: '',
		// 	password: ''
		// };
		form.resetForm();
		this.serverErrorMessages = '';
	}
}