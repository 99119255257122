import { Component, OnInit } from '@angular/core';

import {NgForm,FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UserService  } from '../../shared/user.service';


import { environment  } from '../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

	showSucessMessage: boolean;
	serverErrorMessages:string;
	emailnotexist:boolean;

  constructor(private userService:UserService) {

   }

  ngOnInit() {

  }

  onSubmit(form: NgForm){

 this.userService.checkemail(form.value).subscribe(
			(res:any) => { 
                 if(res.docs !=null)
                 {
				this.showSucessMessage = true; 
				this.emailnotexist =false;
				}

				else
				{
				this.emailnotexist = true;
				this.showSucessMessage =false;

				}
				setTimeout(() => this.showSucessMessage = false, 10000);
				
			},
			err => { 
			alert("no");
				if(err.status == 422){
					this.serverErrorMessages = err.error.join('<br/ >');
				}
				else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';	
			}		
		);
	     
	
	}

	



}
