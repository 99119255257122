import { Component, OnInit } from '@angular/core';
import { JobService } from '../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-country-management',
  templateUrl: './country-management.component.html',
  styleUrls: ['./country-management.component.css']
})
export class CountryManagementComponent implements OnInit {

  countries:string;
  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }

  ngOnInit() {
 

	var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }

    this.adminservice.getCountries().subscribe(
			(res:any) => {
				this.countries = res.data;
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
	
			}
		);
  }
	onSubmit(form: NgForm) {
	    this.adminservice.addcountry(form.value).subscribe(
			(res:any) => {
					 if(res.already)
					 {
						this.toastr.errorToastr('Country already exsist');
					 }
					 else
					 {
				this.toastr.successToastr('Country Add Successfullly');
				
				this.router.navigate(['/admin/country-management']);

					 }
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);
	}

	changeCountryStatus(id,status)
	{
		var countrydata = {"countryid":id,"status":status};

		this.adminservice.updatecountry(countrydata).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Country Update Successfullly');
					 
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

	}

	deletecountry(countryid)
	{
		if(confirm("Are you sure to delete ")) {


		this.adminservice.deleteCountry(countryid).subscribe(
			(res:any) => {
					 
				this.toastr.successToastr('Delete Successfullly');
				
				this.router.navigate(['/admin/country-management']);
					 
			},
			err => {
				this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
		
			}
		);

		this.router.navigate(['/admin/country-management']);
	}


else

{
	return false;
}

}

}