import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

import { UserService } from '../../shared/user.service';
import { MessagesService } from '../../shared/messages.service';

@Component({
  selector: 'app-admin-dashboard-header',
  templateUrl: './admin-dashboard-header.component.html',
  styleUrls: ['./admin-dashboard-header.component.css']
})
export class AdminDashboardHeaderComponent implements OnInit {

  responsedata: string;
  allNotifications: any;
  countUnreadMessageNotifications = 0;

  constructor(private router: Router, private userService: UserService, private messagesService: MessagesService, private toastr: ToastrManager) {
    this.messagesService.newMessageReceived()
      .subscribe((data) => {
        if ((data.receiverId1 == localStorage.getItem("id") || data.receiverId2 == localStorage.getItem("id")) && this.router.url != '/admin/message')
          this.messagesService.setNotification(data)
      });

    this.messagesService.getNewNotification()
      .subscribe((data) => {
        this.allNotifications.splice(0, 0, data[0])
        if (data[0].status == 'unread') {
          this.countUnreadMessageNotifications += 1;
        }
      });
  }

  ngOnInit() {
    if (localStorage.getItem("token") == null) {
      this.router.navigate(['/login']);
    }
    var userid = localStorage.getItem("id");
    this.messagesService.joinRoom({ senderId: userid })
    this.userService.gettUser(userid).subscribe(
      (res: any) => {
        if (res.data) {
          this.responsedata = res.data;
        }
      },
      err => {
        alert("error");
      }
    );
    this.messagesService.getNotifications().subscribe(
      (result) => {
        this.allNotifications = result;
        // console.log(result)
        this.allNotifications.map((ele) => {
          if (ele.status == 'unread') {
            this.countUnreadMessageNotifications += 1;
          }
        })
      },
      (error) => {
        this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
      }
    )
  }

  startChat(notification) {
    this.messagesService.setNotificationAsRead(notification._id).subscribe(
      result => {
        if (result['ok'] == 1) {
          this.allNotifications.forEach((element, index) => {
            if (element._id == notification._id) {
              element.status = "read";
              this.countUnreadMessageNotifications -= 1;
            }
            if (this.allNotifications.length == index + 1) {
              localStorage.setItem("chatId", notification.chatRecordId)
              this.router.navigate(['/admin/message']);
            }
          });
        }
      }, error => {
        console.log(error)
      }
    );
  }
}
