import { Component, OnInit } from '@angular/core';
import { JobService } from '../../shared/job.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgForm,FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { AdminService } from '../../shared/admin.service';
import { Router } from '@angular/router';
import { CKEditorModule } from 'ngx-ckeditor';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-static-page-management',
  templateUrl: './static-page-management.component.html',
  styleUrls: ['./static-page-management.component.css']
})
export class StaticPageManagementComponent implements OnInit {
  public editorValue: string = '';
  htmlContents:any;
  
  // editorConfig: AngularEditorConfig = {
	// 	editable: true,
	// 	spellcheck: true,
	// 	height: '20rem',
	// 	minHeight: '10rem',
	// 	placeholder: 'Enter text here...',
	// 	translate: 'yes',
	// 	uploadUrl: 'v1/images' // if needed,
	
  //   };

  config: AngularEditorConfig = { editable: true, spellcheck: true, height: '15rem', minHeight: '5rem', placeholder: 'Enter text here...', translate: 'no' }

    
    pagetitle:any;
  constructor(private JobService: JobService,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }

  ngOnInit() {
    var role =  localStorage.getItem('role');
		if(role =="jobSeekar")
		{
		this.router.navigate(['/findwork']);
		} 
	  if(role == "jobProvider")
		{
		  this.router.navigate(['/jobpost']);
		}

    this.htmlContents ="";
   
  }


  onSubmit(form: NgForm) {
    
    this.adminservice.addPage(form.value).subscribe(
    (res:any) => {
         if(res.already)
         {
          this.toastr.errorToastr('Page Slug already exsist');
         }
         else
         {
      this.toastr.successToastr('Page Add Successfullly');
      this.router.navigate(['/admin/viewpages']);
         }
    },
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}

}
