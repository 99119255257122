import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserService } from '../../shared/user.service';

@Component({
	selector: 'app-sign-up-niah',
	templateUrl: './sign-up-niah.component.html',
	styleUrls: ['./sign-up-niah.component.css']
})
export class SignUpNiahComponent implements OnInit {


	showSucessMessage: boolean;
	serverErrorMessages: string;
	roleType: string;
	constructor(private userService: UserService) { }

	ngOnInit() {
		this.roleType = "jobSeeker";
	}

	onSubmit(form: NgForm) {

		this.userService.postUser(form.value).subscribe(
			res => {
				this.showSucessMessage = true;
				setTimeout(() => this.showSucessMessage = false, 4000);
				this.resetForm(form);
			},
			err => {
				if (err.status == 422) {
					this.serverErrorMessages = err.error.join('<br/ >');
				}
				else
					this.serverErrorMessages = 'Something went wrong. please contact admin.';
			}
		);
	}

	resetForm(form: NgForm) {
		// this.userService.selectedUser = {
		// 	fullName: '',
		// 	email: '',
		// 	password: ''
		// };
		form.resetForm();
		this.serverErrorMessages = '';

	}

}
