import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private userService: UserService) { }
pagedata:any;
  ngOnInit() {
    this.userService.getpage('5cbdb7e9b9c03d3e14d4146f').subscribe(
			(res:any) => {
				this.pagedata = res.data[0];

			},
			err => {
      }
		);
  }

}
