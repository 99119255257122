import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from "jwt-decode";
import { AuthenticationService } from './shared/authentication.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [CookieService]
})
export class AppComponent {
  title = 'Niah';
  @Input() formData;
  serverErrorMessages:string;
  constructor(private cookieService: CookieService,private AuthenticationService: AuthenticationService, private router: Router) {
 
  if(this.cookieService.get('remember'))
  {
    var gettokenvalue = jwt_decode(this.cookieService.get('token'));
    var password = gettokenvalue.password;
    var email    = gettokenvalue.email;

    var cookiedata ={'email':email,'password':password};

    this.AuthenticationService.postUser(cookiedata).subscribe(
      res => {
        //alert()
        var token = res[0].dta;
        var userid = res[0].id;
        var role = res[0].role;
        var fullName = res[0].fullName;
        localStorage.setItem('token', token);
        localStorage.setItem('id', userid);
        localStorage.setItem('fullName', fullName);
        localStorage.setItem('role', role);
        
       // if (role == "admin") {
       //   this.router.navigate(['/admin']);
       // } else {
       //   this.router.navigate(['/dashboard']);
       // }
        //this.showSucessMessage = true; 
        //setTimeout(() => this.showSucessMessage = false, 4000);
        //this.resetForm(form);
      },
      err => {
        if (err.status == 422) {
          this.serverErrorMessages = err.error.join('<br/ >');
        }
      }
    );


  }
}
}