import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { UserService } from '../../../shared/user.service';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, Params, RoutesRecognized } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AdminService } from '../../../shared/admin.service';
@Component({
  selector: 'app-edit-howitwork',
  templateUrl: './edit-howitwork.component.html',
  styleUrls: ['./edit-howitwork.component.css']
})
export class EditHowitworkComponent implements OnInit {

  constructor(private spinnerService: Ng4LoadingSpinnerService,private route: ActivatedRoute,public toastr: ToastrManager,private adminservice:AdminService,private router: Router) { }
  pagedata:any;
  imageSrc: any;
  hid:any;
  baseurl:any;
	public uploaders: FileUploader = new FileUploader({
		url: environment.apiBaseUrl+'/addhowitsection', itemAlias: 'bannerimage'
	});
  ngOnInit() {

    this.baseurl = environment.imgBaseUrl;

    var role =  localStorage.getItem('role');
    if(role =="jobSeekar")
    {
    this.router.navigate(['/findwork']);
    } 
  if(role == "jobProvider")
    {
      this.router.navigate(['/jobpost']);
    }
    this.hid = this.route.snapshot.paramMap.get('id');
    this.adminservice.gethowitworksbyid(this.hid).subscribe(
      (res:any)=>{
        this.pagedata = res.data[0];
        localStorage.setItem("howitwork",this.pagedata.iconImage);
      });
    this.uploaders.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploaders.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			console.log('ImageUpload:uploaded:', item, status, response);
					localStorage.setItem("howitwork", response);
		}
  }

  onSubmit(form: NgForm) {
    this.spinnerService.show();
    setTimeout(() => {
     form.value.sectionid = this.hid;
    this.adminservice.updatehowitworksbyid(form.value).subscribe(
    (res:any) => {
      this.spinnerService.hide();
      this.toastr.successToastr('Add Successfullly');
      this.router.navigate(['/admin/managehowitworks']);
          },
    err => {
      this.toastr.errorToastr('Something went wrong. please contact admin.', 'Error');
  
    }
  );
}, 3000);
}

public imagePath;
imgURL: any;
public message: string;

preview(files) {
  if (files.length === 0)
    return;

  var mimeType = files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message = "Only images are supported.";
    return;
  }

  var reader = new FileReader();
  this.imagePath = files;
  reader.readAsDataURL(files[0]); 
  reader.onload = (_event) => { 
    
    this.imgURL=reader.result;
  }
}
}
