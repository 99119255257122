import { Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignUpNiahComponent } from './user/sign-up-niah/sign-up-niah.component';
import { SignUpTypeComponent } from './user/sign-up-type/sign-up-type.component';
import { LoginComponent } from './user/login/login.component';
import { HomeComponent } from './home/home.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { LoginLayoutComponent } from './_layout/login-layout/login-layout.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { AdminDashboardComponent } from './user/admin-dashboard/admin-dashboard.component';
import { AdminUsersComponent } from './user/admin-users/admin-users.component';
import { AdminUserComponent } from './user/admin-user/admin-user.component';
import { LogoutComponent } from './logout/logout.component';
import { JobpostComponent } from './job/jobpost/jobpost.component';
import { GetjobsComponent } from './job/getjobs/getjobs.component';
import { ViewjobComponent } from './job/viewjob/viewjob.component';
import { DashboardLayoutComponent } from './_layout/dashboard-layout/dashboard-layout.component';
import { AdminDashboardLayoutComponent } from './_layout/admin-dashboard-layout/admin-dashboard-layout.component';
import { ProfileSettingComponent } from './user/profile/profile-setting/profile-setting.component';
import { MyjobsComponent } from './job/myjobs/myjobs.component';
import { MycontractsComponent } from './job/mycontracts/mycontracts.component';
import { SignUpStep2Component } from './user/sign-up-step2/sign-up-step2.component';
import { SignUpStep3Component } from './user/sign-up-step3/sign-up-step3.component';
import { SignUpStep4Component } from './user/sign-up-step4/sign-up-step4.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ViewContractComponent } from './job/view-contract/view-contract.component';
import { VerifyUserComponent } from './user/verify-user/verify-user.component';
import { CountryManagementComponent } from './admin/country-management/country-management.component';
import { ServiceManagementComponent } from './admin/service-management/service-management.component';
import { JobManagementComponent } from './admin/job-management/job-management.component';
import { JobDetailComponent } from './admin/job-detail/job-detail.component';
import { ShowratereviewComponent } from './admin/sections/showratereview/showratereview.component'
import { AllcategoriesComponent } from './pages/allcategories/allcategories.component';

import { EditCountryComponent } from './admin/edit-country/edit-country.component';

import { AddCountryComponent } from './admin/add-country/add-country.component';

import { AddbankComponent } from './admin/addbank/addbank.component';

import { BankManagementComponent } from './admin/bank-management/bank-management.component';

import { EditBankComponent } from './admin/edit-bank/edit-bank.component';

import { AddServiceComponent } from './admin/add-service/add-service.component';

import { EditServiceComponent } from './admin/edit-service/edit-service.component';

import { StaticPageManagementComponent } from './admin/static-page-management/static-page-management.component';

import { MessageComponent } from './message/message.component';

import { AdminMessageComponent } from './admin/admin-message/admin-message.component';

import { TermasConsitionsComponent } from './termas-consitions/termas-consitions.component';

import { InvoicesComponent } from './admin/invoices/invoices.component';

import { ViewProfileComponent } from './user/profile/view-profile/view-profile.component';

import { FindServiceProviderComponent } from './user/find-service-provider/find-service-provider.component';

import { ReportComponent } from './admin/report/report.component';

import { ViewpagesComponent } from './admin/viewpages/viewpages.component';

import { EditPageComponent } from './admin/edit-page/edit-page.component';

import { FaqComponent } from './pages/faq/faq.component';

import { ChangePasswordComponent } from './user/change-password/change-password.component';

import { AboutUsComponent } from './pages/about-us/about-us.component';

import { HomepageManageComponent } from './admin/homepage-manage/homepage-manage.component';

import { HowitworksComponent } from './admin/sections/howitworks/howitworks.component';
import { AddhowitworkComponent } from './admin/sections/addhowitwork/addhowitwork.component';

import { EditHowitworkComponent } from './admin/sections/edit-howitwork/edit-howitwork.component';

import { TestimonialComponent } from './admin/sections/testimonial/testimonial.component';

import { AddtestimonialComponent } from './admin/sections/addtestimonial/addtestimonial.component';
import { EditTestimonialComponent } from './admin/sections/edit-testimonial/edit-testimonial.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactListComponent } from './admin/contact-list/contact-list.component';

import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';




export const appRoutes: Routes = [
	{
		//path: 'signup', component: UserComponent,
		path: 'signuptype', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: SignUpTypeComponent
			}
		]
	},
	{
		//path: 'signup', component: UserComponent,
		path: 'categories', component: SiteLayoutComponent,
		children: [
			{
				path: '', component: AllcategoriesComponent
			}
		]
	},
	{
		path: 'signup-step2', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: SignUpStep2Component
			}
		]
	},
	{
		path: 'signup-step3', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: SignUpStep3Component
			}
		]
	},
	{
		path: 'signup-step4', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: SignUpStep4Component
			}
		]
	},

	{
		path: 'verify/:id', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: VerifyUserComponent
			}
		]
	},
	{
		path: 'forgot-password', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: ForgotPasswordComponent
			}
		]
	},
	{
		path: 'reset-password/:id', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: ResetPasswordComponent
			}
		]
	},
	{
		//path: 'signup', component: UserComponent,
		path: 'signup/:id', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: SignUpComponent
			}
		]
	},
	{
		//path: 'signup', component: UserComponent,
		path: 'signupniah', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: SignUpNiahComponent
			}
		]
	},
	{
		path: '', component: SiteLayoutComponent,
		children: [
			{ path: '', component: HomeComponent },
		]
	},
	{
		//path: 'login', component: UserComponent,
		path: 'login', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: LoginComponent
			}
		]
	},
	{
		path: 'dashboard', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: DashboardComponent
			}
		]
	},

	{
		path: 'faq', component: SiteLayoutComponent,
		children: [
			{
				path: '', component: FaqComponent
			}
		]
	},

	{
		path: 'aboutus', component: SiteLayoutComponent,
		children: [
			{
				path: '', component: AboutUsComponent
			}
		]
	},

	{
		path: 'message', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: MessageComponent
			},
			{
				path: ':id', component: MessageComponent
			}
		]
	},

	{
		path: 'admin/message', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AdminMessageComponent
			}
		]
	},

	{
		path: 'admin', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AdminDashboardComponent
			}
		]
	},

	{
		path: 'admin/showreviews', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: ShowratereviewComponent
			}
		]
	},
	
	{
		path: 'logout', component: LoginLayoutComponent,
		children: [
			{
				path: '', component: LogoutComponent
			}
		]
	},
	{
		path: 'jobpost', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: JobpostComponent
			}
		]
	},
	{
		path: 'findwork', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: GetjobsComponent
			}
		]
	},

	{
		path: 'admin/managehowitworks', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: HowitworksComponent
			}
		]
	},
	
	{
		path: 'admin/addhowitwork', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AddhowitworkComponent
			}
		]
	},

	{
		path: 'admin/edithowitwork/:id', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: EditHowitworkComponent
			}
		]
	},

    {
		path: 'viewcontract/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: ViewContractComponent
			}
		]
	},

	{
		path: 'admin/edit-country/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: EditCountryComponent
			}
		]
	},

	{
		path: 'change-password', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: ChangePasswordComponent
			}
		]
	},

	{
		path: 'viewjob/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: ViewjobComponent
			}
		]
	},
	{
		path: 'profile', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: ProfileSettingComponent
			}
		]
	},
	{
		path: 'myjobs', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: MyjobsComponent
			}
		]
	},
	{
		path: 'terms-conditions', component: SiteLayoutComponent,
		children: [
			{
				path: '', component: TermasConsitionsComponent
			}
		]
	},

	{
		path: 'privacy-policy', component: SiteLayoutComponent,
		children: [
			{
				path: '', component: PrivacyPolicyComponent
			}
		]
	},

	
	{
		path: 'admin/addcountry', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AddCountryComponent
			}
		]
	},


	{
		path: 'admin/users', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AdminUsersComponent
			}
		]
	},

	{
		path: 'admin/country-management', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: CountryManagementComponent
			}
		]
	},

	{
		path: 'admin/banks-management', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: BankManagementComponent
			}
		]
	},
	{
		path: 'admin/homepage-manage', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: HomepageManageComponent
			}
		]
	},
	{
		path: 'admin/service-management', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: ServiceManagementComponent
			}
		]
	},

	{
		path: 'admin/service-management', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: ServiceManagementComponent
			}
		]
	},

	{
		path: 'admin/add-service', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AddServiceComponent
			}
		]
	},

	{
		path: 'admin/edit-service/:id', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: EditServiceComponent
			}
		]
	},

	{
		path: 'admin/edit-bank/:id', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: EditBankComponent
			}
		]
	},

	{
		path: 'admin/jobs', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: JobManagementComponent
			}
		]
	},

	{
		path: 'admin/job/:id', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: JobDetailComponent
			}
		]
	},

	{
		path: 'admin/user/:id', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AdminUserComponent
			}
		]
	},

	{
		path: 'admin/viewpages', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: ViewpagesComponent
			}
		]
	},

	{
		path: 'admin/pages', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: StaticPageManagementComponent
			}
		]
	},

	{
		path: 'admin/editpage/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: EditPageComponent
			}
		]
	}
,
	{
		path: 'admin/addbank', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: AddbankComponent
			}
		]
	},

	{
		path: 'getjob/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: GetjobsComponent
			}
		]
	},
	{
		path: 'mycontracts/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: MycontractsComponent
			}
		]
	},
	{
		path: 'invoice/:id', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: InvoicesComponent
			}
		]
	},
	{
		path: 'viewprofile/:id/:id2', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: ViewProfileComponent
			}
		]
	}
	,
	{
		path: 'find-service-provider', component: DashboardLayoutComponent,
		children: [
			{
				path: '', component: FindServiceProviderComponent
			}
		]
	},
	{
		path: 'admin/report', component: AdminDashboardLayoutComponent,
		children: [
			{
				path: '', component: ReportComponent
			}
		]
	}
,
{
	path: 'admin/testimonial', component: AdminDashboardLayoutComponent,
	children: [
		{
			path: '', component: TestimonialComponent
		}
	]
}
,
{
	path: 'admin/addtestimonial', component: AdminDashboardLayoutComponent,
	children: [
		{
			path: '', component: AddtestimonialComponent
		}
	]
}
,
{
	path: 'admin/edittestimonial/:id', component: AdminDashboardLayoutComponent,
	children: [
		{
			path: '', component: EditTestimonialComponent
		}
	]
}
,
{
	path: 'contact-us', component: SiteLayoutComponent,
	children: [
		{
			path: '', component: ContactUsComponent
		}
	]
}
,
{
	path: 'admin/contactlist', component: AdminDashboardLayoutComponent,
	children: [
		{
			path: '', component: ContactListComponent
		}
	]
}
,
{
	path: 'admin/invoices', component: AdminDashboardLayoutComponent,
	children: [
		{
			path: '', component: InvoicesComponent
		}
	]
}




];