import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Authentication } from './authentication.model';

@Injectable({
	providedIn: 'root'
})

export class AuthenticationService {

	selectedUser: Authentication = {
		email: '',
		password: ''
	}

	constructor(private http: HttpClient) { }

	postUser(user: Authentication) {
		return this.http.post(environment.apiBaseUrl + '/login', user);
	}

	public get currentUserValue() {
		return localStorage.getItem('token');
	}
}

